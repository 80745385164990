import React, { useEffect, useMemo, useState, useRef, useLayoutEffect } from "react";
import { DatePicker } from "./DatePicker";
import { getLocalString } from "../../helpers";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import { classNames } from "./shared/Utils";
import { SortIcon, SortUpIcon, SortDownIcon } from "./shared/Icons";
import { loc } from "../../helpers";
import { writeFile, utils } from "xlsx";
import { Pagination } from "./Pagination";
import { HeaderTable } from "./HeaderTable";
import { UserStat } from "./UserStat";
import FilteredView from "./FilteredView";
import { useToast } from "../../contexts/ToastContext";

import mixpanel from "mixpanel-browser";

import jp from "jsonpath";

function getRowsFromDates(startDate, endDate, rows) {
  const startDateAtDayStart = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const endDateAtDayEnd = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);

  const filteredRows = rows.filter((row) => {
    return (
      row.date_diag_timestamp * 1000 >= startDateAtDayStart &&
      row.date_diag_timestamp * 1000 <= endDateAtDayEnd
    );
  });

  return filteredRows.map(r => r.date_diag);
}

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  ...props
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex flex-row items-center mr-3">
      <div className="relative flex flex-1 items-center">
        <img className="absolute left-3" src="assets/search.svg" />
        <input
          type="text"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={loc("Rechercher", props.lang)}
          className="rounded-md bg-white flex w-56 items-center pl-9 font-aeonik text-grey-light border-1 border-grey-300 h-11 placeholder-grey-500 moba-input"
        />
      </div>
    </div>
  );
}

export function NbRows({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  column: { type, typePlural, lang },
}) {
  const count = preGlobalFilteredRows.length;
  const label = count > 1 ? typePlural : type;
  return (
    <span className="whitespace-nowrap text-sm items-center font-aeonik text-grey-500 my-auto ml-3">
      {`${getLocalString(count, lang)} ` + label}
    </span>
  );
}

export function NbDiags({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  column: { type, lang },
}) {
  // SUM of preglobalfilteredrows diags
  var count = 0;
  preGlobalFilteredRows.forEach((row) => {
    count += row.values.number;
  });
  return (
    <span className="whitespace-nowrap text-sm items-center font-aeonik text-grey-500 my-auto ml-3">
      {`${count} ` + loc(type, lang)}
    </span>
  );
}

export function NbModels({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  column: { type, lang },
}) {
  // SUM of preglobalfilteredrows diags
  var count = 0;
  preGlobalFilteredRows.forEach((row) => {
    count += row.values.number;
  });
  return (
    <span className="whitespace-nowrap text-sm items-center font-aeonik text-grey-500 my-auto ml-3">
      {`${preGlobalFilteredRows.length} ` + loc(type, lang)}
    </span>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] != undefined && row.values[id] != "")
        options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  // Render a multi-select box
  return (
    <label className="flex items-center gap-x-2 text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function SelectColumnFilterDate({
  changeFiltersAnalytics,
  filterOpened,
  setFilterOpened,
  column: {
    filterValue,
    filterAnalytics,
    setFilter,
    preFilteredRows,
    id,
    render,
  },
  lang,
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [opacity, setOpacity] = useState("opacity-50");
  const [days, setDays] = useState([]);
  const [show, setShow] = useState(false);
  const [toPrint, setToPrint] = useState("Date");

  useEffect(() => {
    if (filterOpened === "filter") setShow(false);
  }, [filterOpened]);

  var onChange = null;

  if (filterAnalytics) {
    onChange = (dates) => {
      if (dates) {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start != null && end != null)
          changeFiltersAnalytics({ date: dates });
        else changeFiltersAnalytics();
        setOpacity("opacity-0");
      } else {
        setStartDate(null);
        setEndDate(null);
        changeFiltersAnalytics({ date: null });
        setOpacity("opacity-0");
      }
    };
  } else {
    onChange = (days) => {
      if (days) {
        setFilter(
          getRowsFromDates(
            days[0],
            days.length == 2 ? days[1] : days[0],
            preFilteredRows.map((row) => row.original)
          )
        );
      } else {
        setFilter(null);
      }
      setOpacity("opacity-0");
    };
  }

  return (
    <div className="flex">
      <DatePicker
        opacity={opacity}
        days={days}
        setDays={setDays}
        show={show}
        setShow={setShow}
        filterOpened={filterOpened}
        setFilterOpened={setFilterOpened}
        startDate={startDate}
        endDate={endDate}
        toPrint={toPrint}
        setToPrint={setToPrint}
        onChange={onChange}
        lang={lang}
      />
    </div>
  );
}

export function DownloadCSV({ data, columns, column: { lang, type }, rows }) {
  const { showToast } = useToast();
  const handleClick = () => {
    var results = [];
    //we iterate on all the rows
    rows.forEach((row) => {
      row = row.original;
      var result = {};
      //we iterate on all the columns
      columns.forEach((column) => {
        if (column.id === "Visualiser" || column.id === "View") return;
        if (column.id === "infos") {
          if (row[column.id] === "NONE") var value = loc("Oui", lang);
          else var value = loc("Non", lang);
        } else if (row[column.id + "_export"] != undefined) {
          var value = row[column.id + "_export"];
        } else if (
          typeof row[column.id] === "object" &&
          !Array.isArray(row[column.id]) &&
          row[column.id] !== null
        ) {
          var value = row[column.id].name;
        } else {
          var value = row[column.id];
        }
        //we add the value to the results array
        result[column.Header] = value;
      });
      //we split the column date into two columns date and time
      if (result["Date"]) {
        //11/02/2021 09:09:58
        const date = result["Date"].split(" ")[0];
        const time = result["Date"].split(" ")[1];
        result["Date"] = date;
        result["Time"] = time;
      }
      results.push(result);
    });
    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(results);
    utils.book_append_sheet(workbook, worksheet, "Sheet1");

    writeFile(workbook, type ? `${type}.xlsx` : "data.xlsx");
    showToast(
      loc("Données téléchargées", lang),
      loc("Les données ont bien été téléchargées", lang),
      "success"
    );

    mixpanel.track("User Downloaded XLSX Data", {
      "Data Type": type
    });
  };
  return (
    <button
      className="flex items-center p-2 border-grey-300 text-grey-500 border-1 rounded-md my-auto ml-auto hover:border-branding-500 hover:text-branding-500"
      onClick={handleClick}
    >
      <p className="font-aeonik text-base mr-4 whitespace-nowrap">
        {loc("Télécharger en .XLSX", lang)}
      </p>
      <img src="assets/excel.svg" alt="excel" className="mr-2" />
    </button>
  );
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("active") ? "bg-green-100 text-green-800" : null,
        status.startsWith("inactive") ? "bg-yellow-100 text-yellow-800" : null,
        status.startsWith("offline") ? "bg-red-100 text-red-800" : null
      )}
    >
      {status}
    </span>
  );
}

export function DateAnalytics({ filterAnalytics, changeFiltersAnalytics }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [opacity, setOpacity] = useState("opacity-50");
  if (filterAnalytics) {
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      if (start != null && end != null) changeFiltersAnalytics({ date: dates });
      setOpacity("opacity-0");
    };
    return (
      <label className="flex gap-x-2 items-end text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
        <div className="relative">
          <div
            className={
              "absolute z-10 flex flex-row items-center left-2 top-2 " + opacity
            }
          >
            <img src="assets/calendar.png" className="mb-2" alt="calendar" />
            <label className="text-xl text-grey ml-2 pointer-events-none ml-0 mr-auto mt-4 mb-2 font-aeonik font-medium">
              Date...{" "}
            </label>
          </div>
          <DatePicker
            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 items-end"
            onChange={onChange}
            selected={startDate}
            selectsRange
            startDate={startDate}
            endDate={endDate}
          ></DatePicker>
        </div>
      </label>
    );
  }
  return null;
}

function Table({
  columns,
  data,
  show,
  add,
  lang,
  analyticsStat,
  entity_details,
  filterAnalytics,
  changeFiltersAnalytics,
  moba,
  ...props
}) {
  const getObjectLabel = (object) => {
    if (object === null || object === undefined) {
      return "-";
    }
    if (Array.isArray(object)) {
      return object.map((item) => getObjectLabel(item)).join(", ");
    }
    return object.name || object.label || object;
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const additionalSortTypes = React.useMemo(
    () => ({
      object: (rowA, rowB, columnId, desc) => {
        const rowAObjectVal = jp.value(rowA.original, columnId);
        const rowBObjectVal = jp.value(rowB.original, columnId);
        const rowALabel = getObjectLabel(rowAObjectVal);
        const rowBLabel = getObjectLabel(rowBObjectVal);
        const rowAEmpty =
          rowALabel === null ||
          rowALabel === undefined ||
          rowALabel === "" ||
          rowALabel === "-" ||
          rowALabel === "--";
        const rowBEmpty =
          rowBLabel === null ||
          rowBLabel === undefined ||
          rowBLabel === "" ||
          rowBLabel === "-" ||
          rowALabel === "--";

        if (rowAEmpty && rowBEmpty) {
          return 0;
        } else if (rowAEmpty) {
          return 1;
        } else if (rowBEmpty) {
          return -1;
        }

        return rowALabel.localeCompare(rowBLabel);
      },
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
  } = useTable(
    {
      columns,
      data,
      sortTypes: additionalSortTypes,
      initialState: {
        pageSize: 10,
        sortBy: [
          {
            id: "date_diag",
            desc: false,
          },
        ],
      },
      disableSortRemove: true,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );
  const [filterOpened, setFilterOpened] = useState("");
  const [sitesFiltered, setSitesFiltered] = useState(null);
  const [rolesFiltered, setRolesFiltered] = useState(null);
  const [teamsFiltered, setTeamsFiltered] = useState(null);
  const [countriesFiltered, setCountriesFiltered] = useState(null);
  const [diagCostFiltered, setDiagCostFiltered] = useState(null);
  const [delegatorsFiltered, setDelegatorsFiltered] = useState(null);
  const [delegationsFiltered, setDelegationsFiltered] = useState(null);

  const tableElement = useRef(null);
  const [windowWidth, windowHeight] = useWindowSize();


  const filterActive = useMemo(() => {
    return (
      sitesFiltered?.length ||
      rolesFiltered?.length ||
      teamsFiltered?.length ||
      countriesFiltered?.length ||
      diagCostFiltered?.length
    );
  }, [sitesFiltered, rolesFiltered, teamsFiltered, countriesFiltered, diagCostFiltered]);


  useEffect(() => {
    setTimeout(() => {
      const pageSize = computePageSize();
      setPageSize(pageSize);
    }, 0);
  }, [windowHeight, filterActive])

  const computePageSize = () => {
    const marginBottom = 40;
    if (!tableElement.current) {
      return 1;
    }
    const mobaTableBodyElement = tableElement?.current.querySelector("tbody");
    const mobaTableBodyRowElement = tableElement?.current.querySelector("tbody tr");
    const DEFAULT_ROW_HEIGHT = 40;

    const mobaTablefooterElement = document.querySelector(".moba-table-pagination");
    
    if (!mobaTablefooterElement) return 10;

    const mobaTableBodyOriginY = mobaTableBodyElement?.getBoundingClientRect().top;
    const mobaFooterHeight = mobaTablefooterElement?.clientHeight;
    const screenHeight = window.innerHeight;
    const remainingHeight = screenHeight - mobaTableBodyOriginY - mobaFooterHeight - marginBottom;
    const rowHeight = mobaTableBodyRowElement?.clientHeight || DEFAULT_ROW_HEIGHT;

    const pageSize = Math.max(1, Math.floor(remainingHeight / rowHeight));

    return pageSize;
  }


  // Render the UI for your table
  return (
    <div className="flex flex-col h-full w-full">
      <HeaderTable
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        state={state}
        lang={lang}
        headerGroups={headerGroups}
        type={props.type}
        adduser={props.adduser}
        add={add}
        show={show}
        changeFiltersAnalytics={changeFiltersAnalytics}
        setFilterOpened={setFilterOpened}
        filterOpened={filterOpened}
        rows={rows}
        sitesFiltered={sitesFiltered}
        setSitesFiltered={setSitesFiltered}
        rolesFiltered={rolesFiltered}
        setRolesFiltered={setRolesFiltered}
        teamsFiltered={teamsFiltered}
        setTeamsFiltered={setTeamsFiltered}
        countriesFiltered={countriesFiltered}
        setDiagCostFiltered={setDiagCostFiltered}
        diagCostFiltered={diagCostFiltered}
        setCountriesFiltered={setCountriesFiltered}
        delegatorsFiltered={delegatorsFiltered}
        setDelegatorsFiltered={setDelegatorsFiltered}
        delegationsFiltered={delegationsFiltered}
        setDelegationsFiltered={setDelegationsFiltered}
      />
      <div className="flex">
        <FilteredView
          lang={lang}
          itemsFiltered={sitesFiltered}
          setItemsFiltered={setSitesFiltered}
          filterType="SITES"
        />
        <FilteredView
          lang={lang}
          itemsFiltered={teamsFiltered}
          setItemsFiltered={setTeamsFiltered}
          filterType="TEAMS"
        />
        <FilteredView
          lang={lang}
          itemsFiltered={rolesFiltered}
          setItemsFiltered={setRolesFiltered}
          filterType="ROLES"
        />
        <FilteredView
          lang={lang}
          itemsFiltered={countriesFiltered}
          setItemsFiltered={setCountriesFiltered}
          filterType="COUNTRIES"
        />
        <FilteredView
          lang={lang}
          itemsFiltered={diagCostFiltered}
          setItemsFiltered={setDiagCostFiltered}
          filterType="DIAGNOSTICS"
        />
        <FilteredView
          lang={lang}
          itemsFiltered={delegatorsFiltered}
          setItemsFiltered={setDelegatorsFiltered}
          filterType="DELEGATORS"
        />
        <FilteredView
          lang={lang}
          itemsFiltered={delegationsFiltered}
          setItemsFiltered={setDelegationsFiltered}
          filterType="DELEGATIONS"
        />
      </div>
      <div
        className={
          "mt-3 flex-1" +
          (entity_details || analyticsStat
            ? " flex"
            : " flex-grow w-full h-full")
        }
      >
        <div className="flex-grow h-full w-full">
          {/* table */}
          <div
            className={
              "flex flex-col font-aeonik mx-auto h-full " +
              (entity_details || analyticsStat ? " w-full" : " w-full")
            }
          >
            <div className={"shadow-sm shadow-shadow border sm:rounded-lg flex flex-col bg-white " + (!canNextPage ? 'h-full' : '')}>
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-grey-200 moba-table"
                ref={tableElement}
              >
                <thead>
                  {headerGroups.map((headerGroup, grpI) => (
                    <tr
                      key={`header-${grpI}`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column, colI) => {
                        if (column.show && column.show() === false) return null;
                        return (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          key={`header-${grpI}-${colI}`}
                          style={{ width: `80px` }} // Applying the width here
                          className="group px-6 py-3 text-left text-xs font-medium tracking-wider"
                        >
                          <div
                            {...column.getSortByToggleProps()}
                            className="flex m-auto items-center justify-between text-xs font-aeonik text-grey-light font-medium cursor-pointer"
                            style={{ minWidth: `60px`, margin: "auto" }}
                          >
                            {column.render("Header")}
                            {column.id !== "download-csv" && (
                              <span>
                                {/* Add a sort direction indicator */}
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownIcon className="w-4 h-4" />
                                  ) : (
                                    <SortUpIcon className="w-4 h-4" />
                                  )
                                ) : (
                                  <SortIcon className="w-4 h-4 opacity-0 group-hover:opacity-100" />
                                )}
                              </span>
                            )}
                          </div>
                        </th>
                      )}
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-grey-200"
                >
                  {page.map((row, rowI) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={`row-${rowI}`}
                        className="h-20"
                      >
                        {
                        row.cells.map((cell, cellI) => {
                          if (cell.column.show && cell.column.show() === false) return null;
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-3 whitespace-nowrap 2xl:max-w-48 xl:max-w-36 max-w-20"
                              role="cell"
                              key={`cell-${rowI}-${cellI}`}
                              style={{
                                overflow: 'hidden',
                              }}
                            >
                              <div className="text-sm font-normal text-grey-500 flex truncate">
                                {cell.render("Cell")}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {pageCount === 0 ? (
                <p className=" bg-white text-grey-500 text-center py-8 flex-1 flex justify-center items-center">
                  {loc("Aucune donnée disponible", lang)}
                </p>
              ) : (
                <>
                  <div className="2xl:flex-1 border-t-1 border-grey-200 moba-table-footer h-100 flex">
                    <Pagination
                      gotoPage={gotoPage}
                      canPreviousPage={canPreviousPage}
                      previousPage={previousPage}
                      canNextPage={canNextPage}
                      nextPage={nextPage}
                      pageOptions={pageOptions}
                      pageCount={pageCount}
                      state={state}
                      setPageSize={setPageSize}
                      lang={lang}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {entity_details && (
          <div className="flex flex-1">
            <div className="flex-1 ml-3">
              <UserStat entity_details={entity_details} lang={lang} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Table;
