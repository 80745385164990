import React from "react";
import Table from "./Table"; // new
import "./css/index.css";
import "./css/react-datepicker.css";

function MobaUserTable(props) {
	const columns = React.useMemo(() => props.columns, [props.columns]);

	return (
		<div className="h-full text-gray-900 flex flex-1">
			<main className="mx-auto flex flex-1">
				<div className="flex flex-1 mt-6">
					<Table
						columns={columns}
						data={props.data}
						show={props.show}
						add={props.add}
						lang={props.lang}
						adduser={true}
						entity_details={props.entity_details}
                        moba={props.moba}
					/>
				</div>
			</main>
		</div>
	);
}

export default MobaUserTable;
