import locMapping from './assets/loc.json';

export function getLangFromLocale(locale) {
  if (locale === "fr_FR" || locale === "fr") return "fr";
  if (locale === "en_UK" || locale === "en_WW" || locale === "en") return "en";
  return "en";
}

export function localeFromLang(lang) {
  if (!lang) {
    return "en_WW";
  }
  if (lang === "en") {
    return "en_WW";
  } else if (lang === "fr") {
    return "fr_FR";
  }
  return lang;
}

export function loc(a, lang) {
	if (locMapping[a] && lang === "en") return locMapping[a];
	return a;
}
export function convertMiles(km) {
	return Math.round(km * 0.621371);
}

export function getUnitFromLocale(locale) {
    return locale === "en_UK" ? "miles" : "km";
}

export function getLocalString(s, lang) {
    if (!s) return s;
    return lang === "en_UK" ? s.toLocaleString("en-UK") : s.toLocaleString("fr-FR");
}

export function pathImgCar(car) {
	if (car === undefined) return "assets/cars/no-vehicle.png";
	car = car.trim();
	if (car === "Renault Zoé") return "assets/cars/zoe.png";
	if (car === "Renault Kangoo 22 kWh") return "assets/cars/kangoo.png";
	if (car === "Nissan Leaf") return "assets/cars/leaf.png";
	if (car === "Peugeot iOn") return "assets/cars/ion.png";
	if (car === "Citroën C-Zero") return "assets/cars/czero.png";
	if (car === "Mitsubishi i-MiEV") return "assets/cars/i3.png";
	if (car === "Kia Soul EV") return "assets/cars/i3.png";
	if (car === "BMW i3") return "assets/cars/i3.png";
	//if (car === "Nissan e-NV200") return "assets/cars/NV200.png"
	if (car === "Tesla Model 3") return "assets/cars/model3.png";
	if (car === "Kia eNiro") return "assets/cars/niro.png";
	if (car === "Hyundai Kona") return "assets/cars/kona.png";
	if (car === "Volkswagen e-up") return "assets/cars/e-up.png";
	if (car === "Skoda Citigo") return "assets/cars/citigo.png";
	if (car === "Seat Mii") return "assets/cars/mii.png";
	if (car === "Mercedes EQA") return "assets/cars/mercedes-eqa.png";
	if (car === "Renault Master") return "assets/cars/master.png";
	if (car === "Kia eNiro PHEV") return "assets/cars/niro.png";
	if (car === "Hyundai Ioniq PHEV") return "assets/cars/ioniq.png";
	if (car === "MG ZS EV 44,5 kWh") return "assets/cars/mg.png";
	if (car === "Peugeot e208 50kWh") return "assets/cars/e208.png";
	if (car === "Peugeot e2008 50kWh") return "assets/cars/e2008.png";
	if (car === "Opel eCorsa 50kWh") return "assets/cars/ecorsa.png";
	if (car === "Opel Mokka-e 50kWh") return "assets/cars/mokka.png";
	if (car === "Citroen C4 50kWh") return "assets/cars/c4.png";
	if (car === "DS 3 50kWh") return "assets/cars/ds3.png";
	//if (car === "Smart Forfour 18,5 kWh") return "assets/cars/forfour.png"
	if (car === "Smart Fortwo 18,5 kWh") return "assets/cars/fortwo.png";
	//if (car === "Mini phev 10 kWh") return "assets/cars/phev.png"
	if (car === "Peugeot E-Expert") return "assets/cars/expert.png";
	if (car === "Peugeot E-Traveller") return "assets/cars/traveller.png";
	if (car === "Citroen e-Jumpy") return "assets/cars/jumpy.png";
	if (car === "Citroen e-Space Tourer") return "assets/cars/tourer.png";
	if (car === "Jaguar I-Pace 90 kWh") return "assets/cars/jaguar.png";
	//if (car === "Mini Cooper SE") return "assets/cars/cooper.png"
	if (car === "Dacia Spring") return "assets/cars/spring.png";
	if (car === "Volkswagen ID3") return "assets/cars/id3.png";
	if (car === "Volkswagen ID4") return "assets/cars/id4.png";
	if (car === "Skoda Enyaq IV") return "assets/cars/skoda.png";
	if (car === "Renault Twingo") return "assets/cars/twingo.png";
	if (car === "Q4 35 e-tron") return "assets/cars/e-tron.png";
	if (car === "Q4 e-tron") return "assets/cars/e-tron.png";

	if (car.includes("Tesla Model S")) return "assets/cars/models.png";
	if (car.includes("Tesla Model X")) return "assets/cars/modelx.png";

	if (car === "Peugeot 3008 PHEV") return "assets/cars/3008.png";
	if (car === "Peugeot 308 PHEV") return "assets/cars/3008.png";
	if (car === "Peugeot 508 PHEV") return "assets/cars/508.png";
	if (car === "Cupra Born") return "assets/cars/born.png";
	if (car === "Bmw Serie 2 PHEV") return "assets/cars/bmwphev.png";
	if (car === "Renault Megane PHEV") return "assets/cars/megane.png";
	if (car === "Mitsubishi Outlander") return "assets/cars/outlander.png";
	if (car === "Renault Captur PHEV") return "assets/cars/captur.png";
	if (car === "Bmw Serie 3 PHEV") return "assets/cars/bmw-serie3.png";

	return "assets/cars/no-vehicle.png";
}

export function ifNanReturnZero(value) {
	if (isNaN(value)) return 0;
	return value;
}

export function parsePhoneNumber(value) {
	//if there is +33 parse it to 33
	if (value.startsWith("+33")) {
		value = value.replace("+33", "0");
	}
	//remove all spaces
	value = value.replace(/\s/g, "");
	//put space between each 2 numbers
	value = value.replace(/(\d{2})/g, "$1 ");
	return value;
}

export function intersect(array1, array2, key = 'id') {
  return array1.filter((value) => includesMultiType(value, array2, key));
}

export function includesMultiType(value, array, key = 'id') {
    let result = false;
    for (const item of array) {
        if (value && typeof item === "object" && typeof value === "object") {
            result = (item[key] === value[key] && value[key]);
        } else if (typeof item === "object") {
            result = item[key] === value
        } else if (value && typeof value === "object") {
            result = (item === value[key] && value[key])
        } else {
            result = item === value
        }
        if (result) return result;
    }
    return result;
}

export function findByJsonPath(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

export function removeDuplicates(array, key = "id") {
    if (!array) return [];

    if (typeof array[0] === "object") {
        return removeDuplicatesObjects(array, key);
    }
    return [...new Set(array)];
}

export function removeDuplicatesObjects(array, key) {
    return array.filter(
      (v, i, a) => {
        return a.findIndex(t => (t !== null && v !== null && t !== undefined && v !== undefined && findByJsonPath(t, key) === findByJsonPath(v, key))) === i
      });
}

export function compareArrays(array1, array2) {
    if (array1.length !== array2.length) return false;
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) return false;
    }
    return true;
}

export function compareObjects(obj1, obj2) {
    for (let key in obj1) {
        if (obj1[key] !== obj2[key]) return false;
    }
    return true;
}

export function compareObjectsDeep(obj1, obj2) {
    for (let key in obj1) {
        if (typeof obj1[key] === "object") {
            if (!compareObjectsDeep(obj1[key], obj2[key])) return false;
        } else {
            if (obj1[key] !== obj2[key]) return false;
        }
    }
    return true;
}

export function compareArraysOfObjects(array1, array2) {
    if (array1.length !== array2.length) return false;
    for (let i = 0; i < array1.length; i++) {
        if (!compareObjects(array1[i], array2[i])) return false;
    }
    return true;
}

export function compareArraysOfObjectsDeep(array1, array2) {
    if (array1.length !== array2.length) return false;
    for (let i = 0; i < array1.length; i++) {
        if (!compareObjectsDeep(array1[i], array2[i])) return false;
    }
    return true;
}

export function isNullable(value) {
  if (typeof value === "object" && value !== null && !Array.isArray(value)) {
    return Object.keys(value).length === 0 || value?.id === null;
  } else if (Array.isArray(value)) {
    return value.length === 0;
  }
  else {
    return value === null || value === undefined || value === "";
  }
}
