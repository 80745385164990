import { loc } from "../../helpers";
import { useHistory } from "react-router-dom";
import {
  PlusIcon
} from "./Icons";

export default function ManagementBox({ img, title, length, lang, type, samePage, create, buttonLabel, noCta }) {
	const history = useHistory();

	return (
		<div className="p-4 bg-white border border-grey w-64 2xl:w-80 mx-2.5 rounded-lg shadow-sm flex flex-col gap-16">
			<div className="flex flex-row">
				<img src={img} alt="site" width={60} height={60} />
				<div className="text-grey-800 ml-4">
					<p>{loc(title, lang)}</p>
					<h1 className="text-4xl mt-2">{length}</h1>
				</div>
			</div>
			{ !noCta && (<button
				className="flex justify-center items-center gap-5 w-full mx-auto px-20 border-1 border-grey-300 text-grey-500 rounded-md py-2 hover:border-branding-500 hover:text-branding-500"
				onClick={() => {
					if (type === "SITE") {
						history.push("sites?action=create");
					} 
					else if (type === "DEVICE") {
						history.push("sites?action=assignDongle");
					} else {
						if (samePage) {
							create();
						} else {
							if (type === "TEAM") {
								history.push("management?action=createTeam");
							} else if (type === "DEVICE") {
								history.push("management?action=createDongle");
							}
						}
					}
				}}
			>
				<p>{buttonLabel ? buttonLabel : loc("Ajouter", lang)}</p>
				<div>
					<PlusIcon className="w-5"/>
				</div>
			</button>)}
		</div>
	);
}
