import { Component } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import Pannel from "./Pannel";
import Header from "./Header";
import MobaUserTable from "./MobaUserTable";
import Api from "../../Api";
import Dropdown from "react-dropdown";
import MultipleDropwdown from "./MultipleDropdown";
import { loc, includesMultiType, intersect, isNullable } from "../../helpers";
import { DownloadCSV, NbRows } from "./Table";
import { InputForm } from "./InputForm";
import ConfirmationModal from "../components/ConfirmationModal";
import PlusBubble from "../components/PlusBubble";
import { ToastContext } from "../../contexts/ToastContext";
import { FilterMoba } from "./FilterMoba";
import SideModal from "../components/SideModal";
import Tooltip from "../components/Tooltip";
import LoadingSpinner from "../components/LoadingSpinner";

import mixpanel from "mixpanel-browser";

Chart.register(CategoryScale);

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_details: null,
      moba: props.moba,
      users: null,

      showConfirmation: false,
      confirmationOnConfirm: null,
      confirmationOnCancel: null,

      nb_users: null,
      nb_super_admins: null,
      nb_admins: null,
      nb_site_managers: null,
      nb_operators: null,
      showUserActionModal: false,
      site_choosen: null,
      role_choosen: null,
      email_user: "",
      first_name_user: "",
      last_name_user: "",
      send_invite_email: "",
      selector_site: { selectedList: [] },
      selected_sites: [],
      selector_team: { selectedList: [] },
      selected_teams: [],
      teams_options_displayed: [],
      user_action: "CREATE",
      user_to_update_id: 0,
      team_display: "none",
      site_display: "block",
      filterableSitesIndexed: null,
    };

    this.invitationEmails = [
      {
        value: "",
        label: loc("Ne pas envoyer d'email", this.props.lang),
      },
      {
        value: "fr",
        label: loc("Envoyer Email - Français", this.props.lang),
      },
      {
        value: "en",
        label: loc("Envoyer Email - Anglais", this.props.lang),
      },
      {
        value: "nl",
        label: loc("Envoyer Email - Néerlandais", this.props.lang),
      },
    ];

    this.getUserSubmitButtonLabel = () => {
      return this.state.user_action === "CREATE"
        ? loc("Créer", this.props.lang)
        : loc("Modifier", this.props.lang);
    };
    this.getUserModalTitle = () => {
      return this.state.user_action === "CREATE"
        ? loc("Créer un utilisateur", this.props.lang)
        : loc("Modifier un utilisateur", this.props.lang);
    };
    this.getUserSubmitEndpoint = () => {
      return this.state.user_action === "CREATE"
        ? "mobaUserCreateWithEmail"
        : "mobaEditUser";
    };

    this.initUsers = () => {
      const filterableTeams = props.moba.getFilterableTeams(true);
      const filterableRoles = props.moba.getFilterableRoles(true);

      const filterableUsers = props.moba.getFilterableUsers(false);

      filterableUsers.map((user) => {
        user.role = filterableRoles[user.role];
        user.sites = user.sites
          .map((site) => {
            return this.filterableSitesIndexed[site.id];
          })
          .filter((s) => !!s);
        user.teams = user.teams
          .map((team) => {
            return filterableTeams[team.id];
          })
          .filter((t) => !!t);
        user.country = user.sites[0]?.country;
      });

      this.setState({
        users: filterableUsers,
      });
    };

    this.defaultRole = () => {
      const assignableRoles = props.moba.roles_assignable;
      return assignableRoles.find((role) => role.id === "Opérateur");
    };

    this.deleteUserOpen = (id, name) => {
      this.setState({
        showConfirmation: true,
        confirmationOnConfirm: () => {
          this.deleteUser(id), this.setState({ showConfirmation: false });
        },
        confirmationOnCancel: () => this.setState({ showConfirmation: false }),
      });
    };

    this.showCreateUser = () => {
      this.setState({
        showUserActionModal: true,
        role_choosen: this.defaultRole(),
        team_display: "block",
        site_display: "block",
        user_action: "CREATE",
      });
    };
    this.showEditUser = (
      email,
      firstname,
      lastname,
      role,
      sites,
      teams,
      site_id
    ) => {
      if (role.id === "Opérateur") {
        this.setState({ team_display: "block" });
      }

      if (role.id === "Super Admin") {
        this.setState({ site_display: "none" });
      }

      if (sites.length !== 0) {
        sites.forEach((site) => {
          this.state.selector_site.selectedList.push(site);
          this.state.selected_sites.push(site);
        });
      }
      teams.forEach((team) => {
        //check that team don't includes Admin
        var name_team = team.name.split(" - ")[0];
        if (!(name_team == "Admin") && !(name_team == "Site Admin")) {
          this.state.selector_team.selectedList.push(team);
          this.state.selected_teams.push(team);
        }
      });

      this.refreshTeamsOptionsDisplayed();

      this.setState({
        user_action: "EDIT",
        site_choosen: sites,
        role_choosen: role,
        email_user: email,
        first_name_user: firstname,
        last_name_user: lastname,
        showUserActionModal: true,
        user_to_update_id: site_id,
        send_invite_email: "",
      });
    };

    this.deleteUser = (id) => {
      this.state.moba.deleteUser(id, (response) => {
        if (!response.err) {
          this.context.showToast(
            loc("Suppression effectuée", this.props.lang),
            loc("L'utilisateur a été supprimé avec succès", this.props.lang),
            "success"
          );
          this.updateInfos(true);
          mixpanel.track("User Deleted User", {
            "Target User ID": id
          });
        } else {
          this.context.showToast(
            loc("Erreur", this.props.lang),
            response.msg,
            "error"
          );
        }
      });
    };

    this._onSelectSite = (site) => {
      this.setState({ site_choosen: site.value });
    };

    this._onSelectSendInviteEmail = (sendInviteEmail) => {
      this.setState({ send_invite_email: sendInviteEmail.value });
    };

    this.roleToRoleDropdownOption = (role) => {
      return {
        value: role.id,
        label: role.name,
      };
    };

    this.roleDropdownOptionToRole = (role) => {
      return {
        id: role.value,
        name: role.label,
      };
    };

    this._onSelectRole = (role) => {
      const role_choosen = this.roleDropdownOptionToRole(role);
      this.setState({ role_choosen });
      this.setState({
        team_display: role_choosen.id === "Opérateur" ? "block" : "none",
        site_display: role_choosen.id !== "Super Admin" ? "block" : "none",
      });
    };

    this.handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };
    this.handleClose = () => {
      this.setState({
        showUserActionModal: false,
        site_choosen: null,
        role_choosen: null,
        email_user: "",
        first_name_user: "",
        last_name_user: "",
        selector_site: { selectedList: [] },
        selector_team: { selectedList: [] },
        teams_options_displayed: [],
        user_to_update_id: 0,
        team_display: "none",
        site_display: "block",
        selected_sites: [],
        selected_teams: [],
        send_invite_email: "",
      });
    };

    this.columns = [
      {
        Header: loc("Adresse e-mail", this.props.lang),
        accessor: "email",
        Cell: (row) => {
          return (
            <Tooltip overflowOnly={true} className="truncate">
              <div className="truncate">{row.row.original.email}</div>
            </Tooltip>
          );
        },
      },
      {
        Header: loc("Nom", this.props.lang),
        accessor: "name",
        Cell: (row) => {
          return (
            <Tooltip overflowOnly={true} className="truncate">
              <div className="truncate">{row.row.original.name}</div>
            </Tooltip>
          );
        },
      },
      {
        Header: loc("Rôle", this.props.lang),
        accessor: "role",
        sortType: "object",
        Filter: FilterMoba,
        filter: (rows, col_id, filterValue) => {
          return rows.filter((row) => {
            let valid = true;

            for (const filterKey in filterValue) {
              const allowEmpty =
                filterValue[filterKey] === null ||
                includesMultiType(null, filterValue[filterKey], "id");

              if (
                ["team", "site"].includes(filterKey) &&
                filterValue[filterKey]
              ) {
                const plurialKey = filterKey + "s";
                if (isNullable(row.original[plurialKey])) {
                  valid = allowEmpty;
                } else {
                  const intersection = intersect(
                    row.original[plurialKey],
                    filterValue[filterKey]
                  );
                  valid = intersection.length > 0;
                }
              } else if (
                filterValue[filterKey] !== undefined &&
                filterValue[filterKey] !== null
              ) {
                if (isNullable(row.original[filterKey])) {
                  valid = allowEmpty;
                } else {
                  valid = includesMultiType(
                    row.original[filterKey],
                    filterValue[filterKey],
                    "id"
                  );
                }
              }
              if (!valid) {
                break;
              }
            }
            return valid;
          });
        },
        moba: this.state.moba,
        filters: ["COUNTRY", "SITE", "TEAM", "ROLE"],
        lang: props.lang,
        Cell: (row) => {
          if (row.row.original.role.id === "Site Admin") {
            return (
              <div className="bg-success-50 py-1 px-2 flex rounded-full">
                <p className="font-normal text-xs text-green m-auto">
                  {loc(row.row.original.role.name, this.props.lang)}
                </p>
              </div>
            );
          }

          else if (row.row.original.role.id === "Site Manager") {
            return (
              <div className="bg-purple-50 py-1 px-2 flex rounded-full">
                <p className="font-normal text-xs text-purple-700 m-auto">
                  {loc(row.row.original.role.name, this.props.lang)}
                </p>
              </div>
            );
          }
          else if (row.row.original.role.id === "Super Admin") {
            return (
              <div className="bg-blue-50 py-1 px-2 text-center rounded-full">
                <p className="font-normal text-xs text-blue-700">
                  {loc(row.row.original.role.name, this.props.lang)}
                </p>
              </div>
            );
          }
          else if (row.row.original.role.id === "Opérateur") {
            return (
              <div className="bg-yellow-light py-1 px-2 flex rounded-full">
                <p className="font-normal text-red text-xs text-yellow-700 m-auto">
                  {loc(row.row.original.role.name, this.props.lang)}
                </p>
              </div>
            );
          }
          else {
            return (
              <div className="bg-purple-50 py-1 px-2 flex rounded-full">
                <p className="font-normal text-xs text-purple-700 m-auto">
                  {loc(row.row.original.role.name, this.props.lang)}
                </p>
              </div>
            );
          }
        },
      },
      {
        Header: loc("Équipes", this.props.lang),
        accessor: "teams",
        sortType: "object",
        type: loc("utilisateur", this.props.lang),
        typePlural: loc("utilisateurs", this.props.lang),
        Filter: NbRows,
        Cell: (row) => {
          return (
            <div className="flex overflow-hidden p-1 items-center">
              <Tooltip overflowOnly={true} className="truncate">
                <div className="truncate">
                  {row.row.original.teams.length > 0
                    ? loc(row.row.original.teams[0].name, this.props.lang)
                    : "-"}
                </div>
              </Tooltip>
              <PlusBubble
                data={row.row.original.teams}
                field="name"
                lang={this.props.lang}
              />
            </div>
          );
        },
      },
      {
        Header: loc("Sites", this.props.lang),
        accessor: "sites",
        sortType: "object",
        Cell: (row) => {
          return (
            <div className="flex overflow-hidden p-1 items-center">
              <Tooltip overflowOnly={true} className="truncate">
                <div className="truncate">
                  {row.row.original.sites.length > 0
                    ? loc(row.row.original.sites[0].name, this.props.lang)
                    : "-"}
                </div>
              </Tooltip>
              <PlusBubble
                data={row.row.original.sites}
                field="name"
                lang={this.props.lang}
              />
            </div>
          );
        },
        lang: this.props.lang,
      },
      {
        Header: loc(" ", this.props.lang),
        Filter: DownloadCSV,
        lang: this.props.lang,
        type: loc("utilisateurs", this.props.lang),
        id: "download-csv",
        Cell: (row) => {
          return (
            <div className="flex flex-1 justify-end gap-5">
              <div className="group">
                <button
                  onClick={(e) =>
                    this.showEditUser(
                      row.row.original.email,
                      row.row.original.firstname,
                      row.row.original.lastname,
                      row.row.original.role,
                      row.row.original.sites,
                      row.row.original.teams.map(this.teamToDropdownTeamOption),
                      row.row.original.id
                    )
                  }
                  href={row.row.original.url}
                  target="_blank"
                >
                  <Tooltip
                    message={loc("Modifier", this.props.lang)}
                    position="left"
                  >
                    <img
                      src="assets/edit.svg"
                      width="20"
                      alt="edit"
                      className="filter grayscale hover:grayscale-0"
                    />
                  </Tooltip>
                </button>
              </div>
              {row.row.original.id !== this.props.moba.details.user.id && (
                <div className="group">
                  <button
                    onClick={(e) =>
                      this.deleteUserOpen(
                        row.row.original.id,
                        row.row.values.name,
                        e
                      )
                    }
                  >
                    <Tooltip
                      message={loc("Supprimer", this.props.lang)}
                      position="left"
                    >
                      <img
                        src="assets/delete.svg"
                        width="20"
                        alt="delete"
                        className="filter grayscale hover:grayscale-0"
                      />
                    </Tooltip>
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ];

    this.updateInfos = (force = false) => {
      this.state.moba.updateInfos(() => {
        this.setState({
          entity_details: this.state.moba.entity_details,
        });
        this.filterableSitesIndexed = this.state.moba.getFilterableSites(true);
        this.initUsers();
      }, force);
    };

    this.handleUserCreateOrEdit = () => {
      const apiEndpoint = this.getUserSubmitEndpoint();

      const toastTitle =
        this.state.user_action === "CREATE"
          ? loc("Utilisateur créé", this.props.lang)
          : loc("Utilisateur modifié", this.props.lang);
      const toastMessage =
        this.state.user_action === "CREATE"
          ? loc("L'utilisateur a été créé avec succès", this.props.lang)
          : loc("L'utilisateur a été modifié avec succès", this.props.lang);

        const trackEvent = this.state.user_action === "CREATE" ? "User Created User" : "User Edited User";
      
      new Api().fetch(
        apiEndpoint,
        {
          id: this.state.user_to_update_id,
          email: this.state.email_user,
          first_name: this.state.first_name_user,
          last_name: this.state.last_name_user,
          role: this.state.role_choosen.id,
          teams: this.state.selected_teams.map((a) => a.id),
          sites: this.state.selected_sites.map((a) => a.id),
          send_invite_email: this.state.send_invite_email,
        },
        (result) => {
          if (result.err) {
            this.context.showToast(
              loc("Erreur", this.props.lang),
              result.msg,
              "error"
            );
          } else {
            this.handleClose();
            this.context.showToast(toastTitle, toastMessage, "success");
            this.updateInfos(true);
            mixpanel.track(trackEvent, {
              "Target User ID": result.user.id,
              "Target User Email": result.user.email
            });
          }
        }
      );
    };

    this.roleAssignableForSelect = () => {
      return this.state.moba.roles_assignable.map((role) => {
        return {
          value: role.id,
          label: loc(role.name, this.props.lang)
        };
      });
    };

    this.teamToDropdownTeamOption = (team) => {
      const siteName =
        this.filterableSitesIndexed[team.site]?.name ||
        loc("Aucun site", this.props.lang);
      const teamId = parseInt(team.id);
      const siteId = parseInt(team.site || 0);
      return {
        id: teamId,
        name: `${team.name} - ${siteName}`,
        siteId: siteId,
      };
    };

    this.isUserCreateOrEditFormValid = () => {
      const isFormInvalid =
        this.state.email_user === "" ||
        this.state.first_name_user === "" ||
        this.state.last_name_user === "" ||
        this.state.role_choosen === null ||
        (this.state.role_choosen.id === "Site Admin" &&
          this.state.selector_site.selectedList.length === 0) ||
        (this.state.role_choosen.id === "Site Manager" &&
          this.state.selector_site.selectedList.length === 0) ||
        (this.state.role_choosen.id === "Opérateur" &&
          (this.state.selected_sites.length === 0 ||
            this.state.selected_teams.length === 0));
      return !isFormInvalid;
    };

    this.getTeamsOptionsDisplayed = () => {
      const filterableTeams = this.state.moba.getFilterableTeams(false);
      const teamsOptionsDisplayed = filterableTeams
        .filter((team) => {
          return team.name !== "Admin" && team.name !== "Site Admin";
        })
        .filter((team) => {
          return team.delegation === null
        })
        .filter((team) => {
          return this.state.selector_site.selectedList.some(
            (selectedSite) =>
              selectedSite.id.toString() === team.site.toString()
          );
        })
        .filter((team) => {
          return !this.state.selector_team.selectedList.some((selectedTeam) => {
            return selectedTeam.id === team.id;
          });
        })
        .map(this.teamToDropdownTeamOption);

      return teamsOptionsDisplayed;
    };

    this.refreshTeamsOptionsDisplayed = () => {
      this.setState({
        teams_options_displayed: this.getTeamsOptionsDisplayed(),
      });
    };

    this.onChangeSite = () => {
      const teamsOptionsDisplayed = this.getTeamsOptionsDisplayed();

      const selectedSites = JSON.parse(
        JSON.stringify(this.state.selector_site.selectedList)
      );
      const selectedTeams = this.state.selector_team.selectedList.filter(
        (team) => {
          return selectedSites.some((site) => {
            return site.id === team.siteId;
          });
        }
      );

      this.setState({
        site_choosen: this.state.selector_site.selectedList,
        teams_options_displayed: teamsOptionsDisplayed,
        selected_teams: selectedTeams,
        selected_sites: selectedSites,
      });
    };
  }

  componentDidMount() {
    mixpanel.track("User Opened Page", { page: "Users" });
    this.updateInfos();
  }

  render() {
    if (this.state.moba.isLoading() || !this.state.users)
      return (
        <div className="flex flex-row min-h-screen m-0">
          <Pannel lang={this.props.lang} />
          <LoadingSpinner/>
        </div>
      );
    else
      return (
        <div className="flex h-full min-h-screen overflow-y-hidden">
          <Pannel type="users" lang={this.props.lang} />
          <div className="flex flex-col flex-grow">
            <Header
              connected={true}
              moba={this.props.moba}
              lang={this.props.lang}
            />
            <div className="flex flex-1 flex-col m-8">
              <div className="flex flex-grow">
                <div className="flex flex-1 flex-col">
                  <h1 className="font-Inter font-bold text-4xl text-grey-700">
                    {loc("Utilisateurs", this.props.lang)}
                  </h1>
                  <div className="flex-1 flex items-start" id="TABLE_CONTAINER">
                    <MobaUserTable
                      data={this.state.users}
                      columns={this.columns}
                      show={this.showCreateUser}
                      add={loc("Ajouter un utilisateur", this.props.lang)}
                      lang={this.props.lang}
                      entity_details={this.state.entity_details}
                      moba={this.state.moba}
                    />
                  </div>
                </div>
              </div>
              <ConfirmationModal
                show={this.state.showConfirmation}
                title={loc("Supression d'un utilisateur", this.props.lang)}
                message={loc(
                  "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
                  this.props.lang
                )}
                onConfirm={this.state.confirmationOnConfirm}
                onCancel={this.state.confirmationOnCancel}
                lang={this.props.lang}
                confirmLabel={loc("Oui", this.props.lang)}
                cancelLabel={loc("Non", this.props.lang)}
              ></ConfirmationModal>
              {this.state.showUserActionModal && (
                <SideModal
                  show={this.state.showUserActionModal}
                  title={this.getUserModalTitle()}
                  onConfirm={this.handleUserCreateOrEdit}
                  onCancel={this.handleClose}
                  confirmLabel={this.getUserSubmitButtonLabel()}
                  lang={this.props.lang}
                  confirmDisabled={!this.isUserCreateOrEditFormValid()}
                >
                  <InputForm
                    lang={this.props.lang}
                    defaultValue={this.state.email_user}
                    handleChange={this.handleChange}
                    name="email_user"
                    title={loc("Email", this.props.lang)}
                    placeholder="john@gmail.com"
                    required={true}
                  />
                  <InputForm
                    lang={this.props.lang}
                    defaultValue={this.state.first_name_user}
                    name="first_name_user"
                    handleChange={this.handleChange}
                    title={loc("Prénom", this.props.lang)}
                    required={true}
                    placeholder="John"
                  />
                  <InputForm
                    lang={this.props.lang}
                    defaultValue={this.state.last_name_user}
                    name="last_name_user"
                    handleChange={this.handleChange}
                    title={loc("Nom", this.props.lang)}
                    required={true}
                    placeholder="Doe"
                  />

                  <div>
                    <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                      {loc("Rôle", this.props.lang)}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Dropdown
                      options={this.roleAssignableForSelect()}
                      onChange={this._onSelectRole}
                      value={this.roleToRoleDropdownOption(
                        this.state.role_choosen
                      )}
                      placeholder={loc(
                        "Choisissez une option",
                        this.props.lang
                      )}
                    />
                  </div>
                  <div
                    className={"flex flex-col "}
                    style={{
                      display: this.state.site_display,
                    }}
                  >
                    <MultipleDropwdown
                      title={
                        <>
                          {loc("Sites", this.props.lang)}
                          <span style={{ color: "red" }}> *</span>
                        </>
                      }
                      options={this.state.moba.getFilterableSites(false)}
                      selector={this.state.selector_site}
                      selectedValue={this.state.selected_sites}
                      onChange={this.onChangeSite}
                    ></MultipleDropwdown>
                  </div>
                  <div
                    className="flex flex-col"
                    id="team_selector"
                    style={{
                      display: this.state.team_display,
                    }}
                  >
                    <MultipleDropwdown
                      title={
                        <>
                          {loc("Équipes", this.props.lang)}
                          <span style={{ color: "red" }}> *</span>
                        </>
                      }
                      options={this.state.teams_options_displayed}
                      selector={this.state.selector_team}
                      selectedValue={this.state.selected_teams}
                      disabled={
                        this.state.selector_site.selectedList.length === 0
                      }
                      onChange={this.onChangeSite} // To recalculate state, for disable button purposes
                    ></MultipleDropwdown>
                  </div>
                  <div className="label-input" id="send_invite_email">
                    <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                      {loc("Envoyer un email d'invitation", this.props.lang)}
                    </label>
                    <Dropdown
                      options={this.invitationEmails}
                      onChange={this._onSelectSendInviteEmail}
                      value={this.state.send_invite_email}
                    ></Dropdown>
                  </div>
                </SideModal>
              )}
            </div>
          </div>
        </div>
      );
  }
}

Users.contextType = ToastContext;

export default Users;
