import Tooltip from "./Tooltip";

const Helper = ({ text, className, showIndicator = true, position }) => {
  return (
    <Tooltip className={ className } message={text} position={position}>
      <div className="cursor-help">
        {showIndicator && (
          <img src="/assets/info.svg" alt="info" className=" w-3 h-3" />
        )}
      </div>
    </Tooltip>
  );
};

export default Helper;
