import React, { useState } from "react";

import "../../App.css";
import Api from "./../../Api.js";
import { useHistory } from "react-router-dom";
import HeaderLogin from "./HeaderLogin";
import Next from "./Next";
import { loc } from "../../helpers";
import FooterLogin from "../components/FooterLogin";
import { useToast } from "../../contexts/ToastContext.js";

export default function SignInPage(props) {
	var history = useHistory();
	const [warn, setWarn] = useState(false);
	const [email, setEmail] = useState("");
	const { showToast } = useToast()
	return (
		<div className="flex flex-col m-0 min-h-screen">
			<HeaderLogin
				connected={false}
				quota={props.moba.quota}
				lang={props.lang}
			/>
			<form
				className="flex flex-col mx-auto font-aeonik"
				onSubmit={async event => {
					//Prevent page reload
					event.preventDefault();

					var { first_name } = document.forms[0];

					new Api().fetch(
						"mobaSignin",
						{
							email: first_name.value,
							code: "",
						},
						result => {
							if (result.err === 0) {
								if (!result.new_user) {
									props.moba.setEmail(first_name.value);
									history.push("/code");
								} else {
									window.location.reload(false);
								}
							} else showToast("Erreur", loc("Adresse e-mail invalide", props.lang));
						}
					);
				}}
			>
				<h2 className="font-medium text-2xl w-128 text-center mt-40">
					{loc(
						"Renseignez votre adresse e-mail pour vous connecter, ou vous inscrire", props.lang
					)}{" "}
					:
				</h2>
				<label className="text-sm text-grey-700 font-aeonik font-light mt-12">
					{loc("Adresse e-mail", props.lang)}
				</label>
				<input
					type="email"
					name="first_name"
					onChange={e => {
						setEmail(e.target.value);
					}}
					required
					className="border border-black rounded-md mb-5 px-2 py-1 moba-input"
				/>
				<Next disabled={email === ''} warn={warn} lang={props.lang}/>
			</form>
			<FooterLogin lang={props.lang} />
		</div>
	);
}
