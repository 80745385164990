import React, { useState } from "react";
import HeaderLogin from "./HeaderLogin";
import Api from "../../Api";
import { useHistory } from "react-router-dom";
import Next from "./Next";
import { loc } from "../../helpers";
import { InputForm } from "./InputForm";
import { set } from "date-fns";
import FooterLogin from "../components/FooterLogin";

export default function Welcome(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  var history = useHistory();
  return (
    <div className="flex flex-col m-0 min-h-screen">
      <HeaderLogin />
      <div className="flex flex-col m-auto">
        <form
          className="flex flex-col mx-auto font-aeonik"
          onSubmit={async (event) => {
            //Prevent page reload
            event.preventDefault();

            new Api().fetch(
              "mobaFormInformation",
              {
                first_name: firstName,
                last_name: lastName,
                company_name: companyName,
                phone: phoneNumber,
              },
              (result) => {
                history.push("/thanks");
              }
            );
          }}
        >
          <div className="flex flex-col my-10">
            <h2 className="font-aeonik text-grey-700 m-auto text-3xl font-medium">
              {loc("Bienvenue", props.lang)} !{" "}
              {loc("Votre compte a été créé avec succès.", props.lang)}
            </h2>
            <p className="self-center mt-8 font-aeonik text-grey-500">
              {loc(
                "Pour commencer, nous avons besoin de quelques informations sur vous."
              )}
            </p>
            <div className="flex flex-col">
              <InputForm
                lang={props.lang}
                name="first_name"
                title={loc("Votre prénom", props.lang)}
                required
                defaultValue={firstName}
                handleChange={(event) => setFirstName(event.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <InputForm
                lang={props.lang}
                name="last_name"
                title={loc("Votre nom", props.lang)}
                required
                defaultValue={lastName}
                handleChange={(event) => setLastName(event.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <InputForm
                lang={props.lang}
                name="company_name"
                title={loc("Le nom de votre entreprise", props.lang)}
                required
                defaultValue={companyName}
                handleChange={(event) => setCompanyName(event.target.value)}
              />
            </div>
            <div className="flex flex-col mb-7">
              <InputForm
                lang={props.lang}
                name="phone_number"
                title={loc("Téléphone", props.lang)}
                required
                defaultValue={phoneNumber}
                handleChange={(event) => setPhoneNumber(event.target.value)}
              />
            </div>
            <Next
              disabled={
                firstName === "" ||
                lastName === "" ||
                companyName === "" ||
                phoneNumber === ""
              }
            />
          </div>
        </form>
      </div>
      <FooterLogin lang={props.lang} />
    </div>
  );
}
