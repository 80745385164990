import Multiselect from "multiselect-react-dropdown";
import { Component } from "react";
import { loc } from "../../helpers";
import { customCloseIcon } from "./customCloseIcon";

class MultipleDropwdown extends Component {
	constructor(props) {
		super();
		this.state = {
			options: props.options,
			selector: props.selector,
			selectedValue: props.selectedValue
		};

		this.onSelect = (selectedList, selectedItem) => {
			this.state.selector.selectedList.push(selectedItem);
			if (props.onChange !== undefined) {
				props.onChange(selectedList, selectedItem);
			}
		};
		this.onRemove = (selectedList, removedItem) => {
			this.state.selector.selectedList =
				this.state.selector.selectedList.filter(
					item => item.id !== removedItem.id
				);
			if (props.onChange !== undefined) {
				props.onChange(selectedList, removedItem);
			}
		};
	}
	render() {
		return (
			<div>
				<label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
					{loc(this.props.title, this.props.lang)}
				</label>
				<Multiselect
					disable={this.props.disabled}
					options={this.props.options} // Options to display in the dropdown
					selectedValues={this.props.selectedValue} // Preselected value to persist in dropdown
					onSelect={this.onSelect} // Function will trigger on select event
					onRemove={this.onRemove} // Function will trigger on remove event
					displayValue={this.props.displayValue || "name"} // Property name to display in the dropdown options
					customCloseIcon={customCloseIcon()}
          style={{
            chips: {
              whiteSpace: 'wrap'
            },
          }}
				/>
			</div>
		);
	}
}
export default MultipleDropwdown;
