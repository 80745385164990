import { loc } from "../../helpers";

export default function Next(props) {
	return (
		<div>
			<button
				type="submit"
				className={`bg-branding-500 px-4 py-2 rounded-md flex w-full ${props.disabled && "opacity-50 cursor-default"}`}
				disabled={props.disabled}
			>
				<p className="text-white mx-auto">
					{loc("Suivant", props.lang)}
				</p>
			</button>
		</div>
	);
}
