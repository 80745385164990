import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { loc } from "../../helpers";

class HeaderLogin extends Component {
	constructor(props) {
		super(props);
		this.state = { connected: props.connected };
	}

	render() {
		return (
			<div className="flex bg-linear-gradient-1 h-48 justify-between items-center px-16">
				<img className="h-20" src="assets/logo.svg" alt="logo" />
				<Button className="w-96 h-16 bg-linear-gradient-2 rounded-xl font-medium text-xl border-none font-Inter not-italic group">
					<a
						target="_blank"
						href="https://www.get-moba.com"
						rel="noreferrer"
						className="text-white hover:no-underline"
					>
						{loc("Visiter le site internet", this.props.lang)}
					</a>
				</Button>
			</div>
		);
	}
}

export default HeaderLogin;
