import { GlobalFilter, DateAnalytics } from "./Table";

export function HeaderTable(props) {
  return (
    <div className="border-b border-grey-200 pb-2 flex min-w-full w-full flex-nowrap items-center">
      <GlobalFilter
        preGlobalFilteredRows={props.preGlobalFilteredRows}
        globalFilter={props.state.globalFilter}
        setGlobalFilter={props.setGlobalFilter}
        lang={props.lang}
      />
      <div className="flex w-full flex-grow items-center">
        {props.headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) => {
            if (column.id && column.id === "download-csv")
              return (
                <div className="flex flex-1" key={column.id}>
                  {column.render("Filter", {
                    lang: props.lang,
                    rows: props.rows,
                  })}
                </div>
              );
            return column.Filter ? (
              <div className="flex" key={column.id}>
                {column.render("Filter", {
                  changeFiltersAnalytics: props.changeFiltersAnalytics,
                  filterOpened: props.filterOpened,
                  setFilterOpened: props.setFilterOpened,
                  lang: props.lang,
                  rows: props.rows,
                  sitesFiltered: props.sitesFiltered,
                  setSitesFiltered: props.setSitesFiltered,

                  diagCostFiltered: props.diagCostFiltered,
                  setDiagCostFiltered: props.setDiagCostFiltered,

                  delegatorsFiltered: props.delegatorsFiltered,
                  setDelegatorsFiltered: props.setDelegatorsFiltered,

                  delegationsFiltered: props.delegationsFiltered,
                  setDelegationsFiltered: props.setDelegationsFiltered,

                  rolesFiltered: props.rolesFiltered,
                  setRolesFiltered: props.setRolesFiltered,

                  teamsFiltered: props.teamsFiltered,
                  setTeamsFiltered: props.setTeamsFiltered,

                  countriesFiltered: props.countriesFiltered,
                  setCountriesFiltered: props.setCountriesFiltered,

                  diagnosticFilterActive: props.diagnosticFilterActive,
                  setDiagnosticFilterActive: props.setDiagnosticFilterActive,
                  
                })}
              </div>
            ) : null;
          })
        )}
        {props.adduser && props.add && (
          <button
            className="flex bg-branding-500 rounded-lg items-center px-4 py-2 ml-10 hover:bg-branding-600"
            onClick={props.show}
          >
            <div className="flex mx-auto">
              <p className="font-aeonik text-white mx-auto">{props.add}</p>
            </div>
          </button>
        )}
      </div>
    </div>
  );
}
