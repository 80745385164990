import React from "react";
import { Modal, Button } from "react-bootstrap";
import { loc } from "../../helpers";

const SideModal = ({
  show,
  title,
  children,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  confirmDisabled,
  lang,
}) => {
  return (
    <Modal
      className="w-96 h-full absolute right-0 side-modal"
      show={show}
      onHide={onCancel}
    >
      <Modal.Header>
        <Modal.Title>
          <p className="ml-4 text-shadow font-aeonik text-3xl font-normal">
            {title}
          </p>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="overflow-scroll">
        <div className="flex flex-col h-full w-full px-2 mx-auto">
        {children}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
            className="flex p-1 flex-1 h-12 border border-1 rounded-md justify-center mx-3"
            variant="primary"
            type="button"
            onClick={onCancel}
        >
            <p className="m-auto font-aeonik">{cancelLabel || loc("Annuler", lang)}</p>
        </button>
        {onConfirm && (
            <button
                className={`flex p-1 flex-1 h-12 border border-1 rounded-md justify-center mx-3 bg-branding-500 ${confirmDisabled && "opacity-50 cursor-default"}`}
                variant="primary"
                type="submit"
                disabled={confirmDisabled}
                onClick={onConfirm}
            >
                <p className="m-auto text-white font-aeonik">{confirmLabel || loc("Confirmer", lang)}</p>
            </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SideModal;
