import React from "react";
import Header from "./Header";
import Pannel from "./Pannel";
import Api from "../../Api";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import MobaTable from "./MobaTable";
import MultipleDropdown from "./MultipleDropdown";
import { TagsInput } from "react-tag-input-component";
//import MultipleDropwdown from "./MultipleDropwdown";
//import InputTag from "./InputTag";
import { loc, includesMultiType, isNullable } from "../../helpers";
import { DownloadCSV, NbRows } from "./Table";
import ManagementBox from "./ManagementBox";
import { InputForm } from "./InputForm";
import { FilterMoba } from "./FilterMoba";
import { ToastContext } from "../../contexts/ToastContext";
import Helper from "../components/Helper";
import Tooltip from "../components/Tooltip";
import SideModal from "../components/SideModal";
import ConfirmationModal from "../components/ConfirmationModal";
import LoadingSpinner from "../components/LoadingSpinner";

import mixpanel from "mixpanel-browser";

export function Teams(props) {
  const filterableTeams = props.moba.getFilterableTeams(false);
  const filterableSites = props.moba.getFilterableSites(true);
  const filterableUsers = props.moba.getFilterableUsers(true);
  const filterableDongles = props.moba.getFilterableDongles(true);

  const teams = filterableTeams
    .filter((team) => {
      return team.name !== "Admin" && team.name !== "Site Admin";
    })
    .map((team) => {
      team.nb_emails = team.emails.length;
      //convert team site to int
      team.key = team.capacities.filter((capacity) => {
        return capacity.type === "QRApp";
      })[0];
      team.site = filterableSites[team.site];
      if (!team.site) {
        team.site = null;
      }
      team.country = team.site?.country
      if (team.key !== undefined) {
        team.key = team.key.code;
        team.anonymous =
          team.key.infos === "FULLNAME"
            ? loc("Non", props.lang)
            : loc("Oui", props.lang);
      } else {
        team.key = "";
        team.anonymous = "";
      }
      team.dongles = team.capacities.filter((capacity) => {
        return capacity.type === "Dongle";
      }).map((capacity) => {
        return filterableDongles[capacity.dongle];
      });

      team.nDongles = team.dongles.length;

      team.users = team.members.map((member) => {
        return filterableUsers[member.user];
      }).filter((user) => !!user);
      return team;
    });

  const columns = [
    {
      Header: loc("Nom", props.lang),
      accessor: "name",
      Cell: (row) => {
        return (
          <Tooltip overflowOnly={true} className="truncate">
            <div className="truncate">
              {row.row.original.name}
            </div>
          </Tooltip>
        );
      },
    },
    {
      Header: loc("Site", props.lang),
      accessor: "site",
      sortType: "object",
      Filter: FilterMoba,
      filter: (rows, col_id, filterValue) => {
        return rows.filter((row) => {
          let valid = true;

          for (let filterKey in filterValue) {
            const allowEmpty = filterValue[filterKey] === null || includesMultiType(null, filterValue[filterKey], "id");

            if (filterValue[filterKey] !== undefined && filterValue[filterKey] !== null) {
              if (isNullable(row.original[filterKey])) {
                valid = allowEmpty
              } else {
                valid = includesMultiType(
                  row.original[filterKey],
                  filterValue[filterKey],
                  "id"
                )
              }
            }
            if (!valid) {
              break;
            }
          }
          return valid;
        });
      },
      filters: ["SITE", "COUNTRY"],
      lang: props.lang,
      moba: props.moba,
      Cell: (row) => {
        return (
            <Tooltip overflowOnly={true} className="truncate">
              <div className="truncate">
                {row.row.original.site?.name || '-'}
              </div>
            </Tooltip>
        );
      },
    },
    {
      Header: loc("E-mail(s) associé(s)", props.lang),
      Filter: NbRows,
      type: loc("équipe", props.lang),
      typePlural: loc("équipes", props.lang),
      accessor: "nb_emails",
    },
    // {
    //   Header: loc("Boîtier(s)", props.lang),
    //   accessor: "nDongles",
    //   lang: props.lang,
    // },
    {
      Header: " ",
      Filter: DownloadCSV,
      lang: props.lang,
      type: loc("équipes", props.lang),
      id: "download-csv",
      Cell: (row) => {
        return (
          <div className="flex flex-1 items-start justify-end gap-5">
            <button
              className="group"
              onClick={(e) => {
                props.showEdit(
                  row.row.original,
                  row.row.original.name,
                  row.row.original.dongles,
                  row.row.original.users
                    .map((user) => {
                      return {
                        id: user.id,
                        name: user.firstname + " " + user.lastname,
                      };
                    })
                    .filter((user) => {
                      return user !== null;
                    }),
                  row.row.original.site,
                  row.row.original.locale,
                  row.row.original.emails,
                  row.row.original.id,
                  row.row.original.infos,
                  row.row.original.n_diags_done === 0
                );
              }}
              href="#"
            >
              <Tooltip message={ loc("Modifier l'équipe", props.lang) } position='left'>
                <img
                  className=" filter grayscale hover:grayscale-0"
                  src="assets/edit.svg"
                  width="20"
                  alt="edit"
                />
              </Tooltip>
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <div className="mx-3 flex flex-1 flex-col">
      <p className="font-aeonik text-4xl mb-4">{loc("Équipes", props.lang)}</p>
      <div className=" mt-0 flex flex-1" id="TABLE_CONTAINER">
        <MobaTable
          data={teams}
          columns={columns}
          lang={props.lang}
          type={loc("équipes", props.lang)}
          moba={props.moba}
        />
      </div>
    </div>
  );
}

class Management extends React.Component {
  constructor(props) {
    super();
    this.sourceRef = React.createRef();
    this.state = {
      entity_details: {},
      showTeam: false,
      team_choosen: null,
      team_key: null,
      type_choosen: loc("Nom/Prénom", props.lang),
      site_choosen: null,
      dongle_choosen: null,
      site_name: null,
      selector_admin: { selectedList: [] },
      selector_managers: { selectedList: [] },
      selector_dongles: { selectedList: [] },
      selected_dongles: [],
      selector_operators: { selectedList: [] },
      selected_operators: [],
      emails_selected: [],
      anonymous_options: "no",
      team_action: "CREATE",
      team_type: "NORMAL",
      team_name: null,
      team_id: 0,
      moba: props.moba,
      locale_chosen: null,
      can_team_be_deleted: false,
      show_confirmation: false,
    };
    this.helpers = {
      teamOperators: loc(
        "Vous pouvez associer plusieurs opérateurs à une équipe. De cette manière, vos opérateurs pourront facilement rejoindre la bonne équipe depuis l'application mobile.",
        props.lang
      ),
      teamEmails: loc(
        "Vous pouvez associer plusieurs e-mails à une équipe. Lorsqu'un e-mail sera envoyé à l'équipe, chaque e-mail associé le recevra.",
        props.lang
      ),
      teamDongles: loc(
        "Associer un boîtier à une équipe permet à vos opérateurs de rejoindre automatiquement l'équipe lorsqu'ils réaliseront un diagnostic avec ce boîtier.",
        props.lang
      ),
      teamDelete: loc(
        "Vous ne pouvez pas supprimer une équipe qui a déja effectué des diagnostics.",
        props.lang
      )
    };
    this.handleClose = () => {
      this.setState({
        showTeam: false,
        showDongle: false,
        team_choosen: null,
        team_key: null,
        type_choosen: loc("Nom/Prénom", props.lang),
        site_choosen: null,
        dongle_choosen: null,
        showTeamActionModal: false,
        site_name: null,
        selector_admin: { selectedList: [] },
        selector_managers: { selectedList: [] },
        selector_dongles: { selectedList: [] },
        selected_dongles: [],
        selector_operators: { selectedList: [] },
        selected_operators: [],
        emails_selected: [],
        anonymous_options: "no",
        team_name: null,
        team_id: 0,
        locale_chosen: null,
        can_team_be_deleted: false,
        show_confirmation: false,
        team_type: "NORMAL"
      });
    };
    this.getTeamSubmitButtonLabel = () => {
      return this.state.team_action === "CREATE" ? loc("Créer", this.props.lang) : loc("Modifier", this.props.lang);
    }
    this.getTeamModalTitle = () => {
      return this.state.team_action === "CREATE" ? loc("Créer une équipe", this.props.lang) : loc("Modifier une équipe", this.props.lang);
    }
    this.getTeamSubmitEndpoint = () => {
      return this.state.team_action === "CREATE" ? "mobaTeamCreate" : "mobaTeamEdit";
    }

    this.handleShowTeam = () => {
      this.setState({
        showTeam: true,
        site_choosen: null,
      });
    };

    this.handleShowDongle = () => {
      this.setState({
        showDongle: true,
      });
    };
    
    this.handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    this.deleteTeam = (e) => {
      new Api().fetch(
        "mobaTeamDelete",
        { id: this.state.team_id },
        (result) => {
          this.handleClose();
          if (result.err) {
            this.context.showToast(
              loc("Erreur", this.props.lang),
              result.msg,
              "error"
            );
          } else {
            this.context.showToast(
              loc("Équipe supprimée", this.props.lang),
              loc("L'équipe a été supprimée avec succès", this.props.lang),
              "success"
            );
            this.updateInfos(true);
          }
        }
      );
    };

    this.updateInfos = async (force = false) => {
      this.state.moba.updateInfos(() => {
        this.setState({
          entity_details: this.state.moba.entity_details,
        });
      }, force);
    };

    this.getDefaultEmails = () => {
      const defaultEmails = [];
      if (this.state.moba.details?.user?.email && this.state.moba.details.user.email !== "") {
        defaultEmails.push(this.state.moba.details.user.email);
      }
      return defaultEmails;
    };

    this.showCreateTeam = () => {
      this.setState({
        showTeamActionModal: true,
        team_action: "CREATE",
        locale_chosen: this.getDefaultLocaleChosen(),
        emails_selected: this.getDefaultEmails()
      });
    }

    this.getTeamType = (team) => {
      if (team.delegator) {
        return "DELEGATION";
      } else if (team.delegation) {
        return "DELEGATOR";
      } else {
        return "NORMAL";
      }
    }

    this.getDefaultLocaleChosen = () => {
      let defaultLocale = this.state.moba.available_locales?.find((locale) => {
        return locale.code === this.props.locale;
      });
      if (!defaultLocale) {
        defaultLocale = this.state.moba.available_locales?.find((locale) => {
          return locale.code === 'en_WW';
        });
      }
      return defaultLocale;
    };

    this.showEditTeam = (
      team,
      name,
      dongles,
      operators,
      site,
      localeCode,
      emails,
      id,
      infos,
      canBeDeleted = false
    ) => {
      dongles.forEach((dongle) => {
        this.state.selector_dongles.selectedList.push(dongle);
        this.state.selected_dongles.push(dongle);
      });
      operators.forEach((operator) => {
        this.state.selector_operators.selectedList.push(operator);
        this.state.selected_operators.push(operator);
      });

      let site_choosen = site ? this.state.entity_details.sites.filter((s) => {
        return s.id === site.id;
      })[0] : null;

      let locale_chosen = this.getDefaultLocaleChosen();
      if (localeCode !== "") {
        locale_chosen = this.state.moba.available_locales?.filter((locale) => {
          return locale.code === localeCode;
        })[0];
      }

      this.setState({
        showTeamActionModal: true,
        team_action: "EDIT",
        team_type: this.getTeamType(team),
        team_id: id,
        team_name: name,
        site_choosen: site_choosen ? {
          value: site_choosen.id,
          label: site_choosen.name,
        } : null,
        locale_chosen: {
          code: locale_chosen.code,
          label: locale_chosen.name,
          icon: locale_chosen.icon,
        },
        emails_selected: emails.map((email) => {
          return email.email;
        }),
        anonymous_options: "no",
        can_team_be_deleted: canBeDeleted,
      });
    };
    this._onSelectType = (type) => {
      this.setState({ type_choosen: type.value });
    };
    this._onSelectSite = (site) => {
      this.setState({
        site_choosen: {
          value: site.value,
          label: site.label,
        },
      });
    };

    this._onSelectLocale = (locale) => {
      const newLocale = this.state.moba.available_locales.find((l) => l.code === locale.value);
      this.setState({
        locale_chosen: newLocale,
      });
    };

    this.handleTeamAction = () => {
      const apiEndpoint = this.getTeamSubmitEndpoint();
      const toastTitle = this.state.team_action === "CREATE" ? loc("Équipe créée", this.props.lang) + " !" : loc("Équipe modifiée", this.props.lang);
      const toastMessage = this.state.team_action === "CREATE" ? loc("L'équipe a été créée avec succès", this.props.lang) : loc("L'équipe a été modifiée avec succès", this.props.lang);
      const trackEvent = this.state.team_action === "CREATE" ? "User Created Team" : "User Edited Team";

      new Api().fetch(
        apiEndpoint,
        {
          id: this.state.team_id,
          name: this.state.team_name,
          site: this.state.site_choosen.value,
          locale: this.state.locale_chosen.code,
          infos: "FULLNAME",
          operators: this.state.selector_operators.selectedList.map(
            (a) => a.id
          ),
          emails: this.state.emails_selected,
          dongles: this.state.selector_dongles.selectedList.map((a) => a.id),
        },
        (result) => {
          this.handleClose();
          if (result.err) {
            this.context.showToast(
              loc("Erreur", this.props.lang),
              result.msg,
              "error"
            );
          } else {
            this.context.showToast(
              toastTitle,
              toastMessage,
              "success"
            );
            this.updateInfos(true);
            mixpanel.track(trackEvent, {
              "Target Team ID": result.team.id,
              "Target Team Name": result.team.name
            });
          }
        }
      );
    };

    this.handleDongleAssociation = () => {
      const dongles = this.state.selector_dongles.selectedList.map((a) => a.id);
      const team = this.state.team_choosen.value;
      new Api().fetch(
        "capacityCreateManyDongles",
        { team, dongles },
        (result) => {
          this.handleClose();
          if (!result || result.err) {
            this.context.showToast(
              loc("Erreur", this.props.lang),
              result?.msg
                ? result.msg
                : loc("Une erreur est survenue", this.props.lang),
              "error"
            );
          } else {
            this.context.showToast(
              loc("Dongle(s) associés", this.props.lang),
              loc(
                "Le(s) dongle(s) ont été associés avec succès à l'équipe",
                this.props.lang
              ),
              "success"
            );
            this.updateInfos(true);

            mixpanel.track("User Paired Devices", {
              "Target Team ID": team,
              "Target Devices ID": dongles
            });
          }
        }
      );
    };

    this.selectEmail = (email) => {
      if (
        !(
          this.state.entity_details === undefined ||
          this.state.entity_details.users === undefined ||
          this.state.moba.isLoading()
        )
      )
        this.setState({ emails_selected: email });
    };
    this.onChange = (e) => {
      this.setState({
        anonymous_options: e,
      });
    };

    this.customStyles = {
      overlay: {
        background: "rgba(0, 0, 0, 0.5)",
      },
      content: {
        height: "200px", //or maxHeight
      },
    };

    this.canEditSite = () => {
      return this.state.team_type === "NORMAL";
    }

    this.canEditTeam = () => {
      return this.state.team_type === "NORMAL";
    }

    this.editTeamDisabledReason = () => {
      if (this.state.team_type === "DELEGATION") {
        return loc("Les équipes déléguées ne peuvent pas être modifiées", this.props.lang);
      } else if (this.state.team_type === "DELEGATOR") {
        return loc("Les équipes délégatrices ne peuvent pas être modifiées", this.props.lang);
      } else {
        return "";
      }
    }

    this.editSiteDisabledReason = () => {
      if (this.state.team_type === "DELEGATION") {
        return loc("Les sites d'équipes déléguées ne peuvent pas être modifiées", this.props.lang);
      } else if (this.state.team_type === "DELEGATOR") {
        return loc("Les sites d'équipes délégatrices ne peuvent pas être modifiées", this.props.lang);
      } else {
        return "";
      }
    }

    this.canAssignOperator = () => {
      return ["NORMAL", "DELEGATION"].includes(this.state.team_type);
    }

    this.canAssignDevices = () => {
      return ["NORMAL", "DELEGATION"].includes(this.state.team_type);
    }

    this.canChangeDiagLocale = () => {
      return ["NORMAL", "DELEGATOR"].includes(this.state.team_type);
    }

  }

  async componentDidMount() {
    mixpanel.track("User Opened Page", { page: "Management" });
    await this.updateInfos();
    //get url after ?
    let url = window.location.href.split("?")[1];
    //if args create = true then show create site
    if (url !== undefined && url.split("=")[1] === "createTeam") {
      this.showCreateTeam();
      // Rewrite URL to remove the query string
      window.history.pushState(
        "",
        document.title,
        window.location.pathname
      );
    } else if (url !== undefined && url.split("=")[1] === "createDongle") {
      this.handleShowDongle();
    }
  }


  render() {
    if (
      this.state.entity_details === undefined ||
      this.state.entity_details === undefined ||
      this.state.entity_details.users === undefined ||
      this.state.moba.isLoading()
    )
      return (
        <div className="flex flex-row min-h-screen m-0">
          <Pannel type="management" lang={this.props.lang} />
					<LoadingSpinner/>
        </div>
      );
    else
      return (
        <div className="flex h-full min-h-screen overflow-y-hidden">
          <Pannel type="management" lang={this.props.lang} />
          <div className="flex flex-col flex-grow pb-8">
            <Header
              connected={true}
              moba={this.props.moba}
              lang={this.props.lang}
            />
            <div className="mt-5 w-full h-full flex flex-row">
              <div className="flex flex-col justify-start py-0 mx-3 gap-9">
              { this.props.moba.isSuperAdmin() && (<ManagementBox
                  length={this.state.entity_details.sites.length}
                  lang={this.props.lang}
                  title={loc("Sites", this.props.lang)}
                  type="SITE"
                  img="assets/sites.svg"
                  samePage={true}
                />)}
                <ManagementBox
                  length={
                    this.state.entity_details.teams.filter((team) => {
                      return (
                        team.name !== "Site Admin" && team.name !== "Admin"
                      );
                    }).length
                  }
                  title={loc("Équipes", this.props.lang)}
                  lang={this.props.lang}
                  create={this.showCreateTeam}
                  type="TEAM"
                  img="assets/teams.svg"
                  samePage={true}
                />
                <ManagementBox
                  length={this.state.entity_details.dongles.length}
                  title={loc("Boîtiers", this.props.lang)}
                  lang={this.props.lang}
                  create={this.handleShowDongle}
                  type="DEVICE"
                  img="assets/dongles.svg"
                  samePage={true}
                  buttonLabel={loc("Associer", this.props.lang)}
                  noCta={!this.props.moba.isSuperAdmin()}
                />
              </div>
              <Teams
                entity={this.state.entity_details}
                showEdit={this.showEditTeam}
                lang={this.props.lang}
                moba={this.state.moba}
              />
            </div>
          </div>
          <div>
          <ConfirmationModal
            show={this.state.show_confirmation}
            title={loc("Supression d'une équipe", this.props.lang)}
            message={loc(
                "Êtes-vous sûr de vouloir supprimer cette équipe ?",
                this.props.lang
            )}
            onConfirm={this.deleteTeam}
            onCancel={() => this.setState({show_confirmation: false})}
            lang={this.props.lang}
            confirmLabel={loc("Oui", this.props.lang)}
            cancelLabel={loc("Non", this.props.lang)}
            ></ConfirmationModal>
            <SideModal
              show={this.state.showTeamActionModal}
              title={this.getTeamModalTitle()}
              onCancel={this.handleClose}
              onConfirm={this.handleTeamAction}
              lang={this.props.lang}
              confirmLabel={this.getTeamSubmitButtonLabel()}
              confirmDisabled={
                !this.state.site_choosen || !this.state.team_name || !this.state.locale_chosen || !this.state.emails_selected.length
              }
            >
              <InputForm
                lang={this.props.lang}
                defaultValue={this.state.team_name}
                handleChange={this.handleChange}
                title={
                  <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                    {loc("Nom de l'équipe", this.props.lang)}
                    <span style={{ color: "red" }}> *</span>
                  </label>
                }
                name="team_name"
                placeholder={`${loc("Équipe", this.props.lang)} 1`}
                disabled={!this.canEditTeam()}
                disabledReason={this.editTeamDisabledReason()}
              />
              <div>
                <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                  {loc("Site", this.props.lang)}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Tooltip
                  className="w-full"
                  message={this.editSiteDisabledReason()}
                  disabled={this.canEditSite()}
                >
                  <Dropdown
                    options={this.state.entity_details.sites.map((site) => {
                      return {
                        label: site.name,
                        value: site.id,
                      };
                    })}
                    onChange={this._onSelectSite}
                    value={this.state.site_choosen}
                    placeholder={loc("Choisissez une option", this.props.lang)}
                    disabled={!this.canEditSite()}
                    controlClassName={!this.canEditSite() && `bg-grey-100 cursor-not-allowed hover:cursor-not-allowed`}
                  />
                </Tooltip>
              </div>
              { this.canAssignOperator() && (
                <div className="flex flex-col">
                  <MultipleDropdown
                    title={
                      <div className="flex items-center">
                        {loc("Opérateur(s) associé(s)", this.props.lang)}
                        <Helper className="ml-2" text={this.helpers.teamOperators} position='top'/>
                      </div>
                    }
                    options={this.state.entity_details.users.map((user) => {
                      return {
                        name: user.firstname + " " + user.lastname,
                        id: user.id,
                      };
                    })}
                    selector={this.state.selector_operators}
                    selectedValue={this.state.selected_operators}
                  ></MultipleDropdown>
                </div>
              )}
              <div className="flex flex-col">
                <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium flex items-center">
                  {loc("E-mail(s) associé(s)", this.props.lang)}
                  <span style={{ color: "red" }}>&nbsp;*</span>
                  <Helper className="ml-2" text={this.helpers.teamEmails} position='top' />
                </label>
                <TagsInput
                  value={this.state.emails_selected}
                  onChange={this.selectEmail}
                  name="emails"
                  placeHolder="E-mail"
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      this.setState({
                        emails_selected: [
                          ...this.state.emails_selected,
                          e.target.value,
                        ],
                      });
                      e.target.value = "";
                    }
                  }}
                />
              </div>
              {/* { this.canAssignDevices() && (
                <div className="flex flex-col">
                  <MultipleDropdown
                    title={
                      <div className="flex items-center">
                        {loc("Boîtiers associé(s)", this.props.lang)}
                        <Helper className="ml-2" text={this.helpers.teamDongles} position='top' />
                      </div>
                    }
                    options={this.state.entity_details.assignableDongles.map(
                      (dongle) => {
                        return {
                          name: dongle.code,
                          id: dongle.id,
                        };
                      }
                    )}
                    selector={this.state.selector_dongles}
                    selectedValue={this.state.selected_dongles}
                  ></MultipleDropdown>
                </div>
              )} */}
              { this.canChangeDiagLocale() && (
                <div>
                  <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
                    {loc("Langue (pour les diagnostics)", this.props.lang)}
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </label>
                  <Dropdown
                    options={this.state.moba.available_locales?.map((locale) => {
                      return {
                        label: (
                          <div className="flex items-center">
                            <img
                              src={locale.icon}
                              alt="flag"
                              className="mr-2 w-4 h-4 object-cover"
                            />
                            {locale.name}
                          </div>
                        ),
                        value: locale.code,
                      };
                    })}
                    onChange={this._onSelectLocale}
                    value={this.state.locale_chosen?.code}
                    placeholder={loc("Choisissez une option", this.props.lang)}
                  />
                </div>
              )}
              <div className="flex">
                {this.state.team_action === "EDIT" ? (
                    <button
                      ref={this.sourceRef}
                      type="button"
                      disabled={!this.state.can_team_be_deleted}
                      className={`flex justify-center border-1 border-error-500 w-full mt-3 rounded-lg p-2 ${
                        !this.state.can_team_be_deleted
                          ? "border-opacity-25 cursor-not-allowed"
                          : ""
                      }`}
                      //className="btn btn-light save modal delete"
                      variant="primary"
                      onClick={() => this.setState({show_confirmation: true})}
                    >
                      <Tooltip
                        message={this.helpers.teamDelete}
                        disabled={this.state.can_team_be_deleted}
                      >
                        {" "}
                        <p
                          className={`text-error-500 ${
                            !this.state.can_team_be_deleted
                              ? "opacity-25"
                              : ""
                          }`}
                        >
                          {loc("Supprimer l'équipe", this.props.lang)}
                        </p>
                      </Tooltip>
                    </button>
                ) : null}
              </div>
            </SideModal>
          </div>
        </div>
      );
  }
}

Management.contextType = ToastContext;

export default Management;
