import React, { useEffect } from "react";
import Table from "./Table"; // new
import "./css/index.css";
import "./css/react-datepicker.css";
import { loc, convertMiles, getLocalString } from "../../helpers";
import Api from "../../Api";
import { InputForm } from "./InputForm";
import Dropdown from "react-dropdown";
import MultipleDropwdown from "./MultipleDropdown";
import SideModal from "../components/SideModal";

function MobaTable(props) {
  var [showCreateUser, setShowCreateUser] = React.useState(false);

  var [user_to_update_id, setUser_to_update_id] = React.useState(0);
  var [email_user, setEmail_user] = React.useState("");
  var [first_name_user, setFirst_name_user] = React.useState("");
  var [last_name_user, setLast_name_user] = React.useState("");
  var [role_choosen, setRole_choosen] = React.useState("");
  var [selector_team, setSelector_team] = React.useState({
    selectedList: [],
  });
  var [selector_site, setSelector_site] = React.useState({
    selectedList: [],
  });
  var [teams, setTeams] = React.useState([]);
  var [sites, setSites] = React.useState([]);
  var [role, setRole] = React.useState([]);
  var [user_title, setUser_title] = React.useState(
    loc("Créer un utilisateur", props.lang)
  );
  var [api_request, setApi_request] = React.useState("mobaUserCreateWithEmail");
  var [selected_teams, setSelected_teams] = React.useState([]);
  var [options_displayed, setOptions_displayed] = React.useState([]);
  var [team_display, setTeam_display] = React.useState("none");
  var [selected_sites, setSelected_sites] = React.useState([]);
  var [user_button_title, setUser_button_title] = React.useState(
    loc("Créer", props.lang)
  );
  var [entity_details, setEntity_details] = React.useState(null);

  var _onSelectRole = (role) => {
    setRole_choosen(role.value);
    if (role.value === loc("Opérateur", props.lang)) setTeam_display("block");
    else setTeam_display("none");
  };

  var showCreateUserFunc = () => {
    setShowCreateUser(true);
  };

  var onChangeSite = () => {
    var options_displayed = entity_details.teams
      .filter((team) => {
        return team.name !== "Admin" && team.name !== "Site Admin";
      })
      .filter((team) => {
        return selector_site.selectedList
          .map((selected) => {
            return selected.id.toString();
          })
          .includes(team.site.toString());
      })
      .map((team) => {
        //convert to number team.site
        team.site = parseInt(team.site);
        return {
          id: team.id,
          name:
            team.name +
            " - " +
            entity_details.sites.find((site) => {
              return site.id === team.site;
            }).name,
        };
      });

    setOptions_displayed(options_displayed);
  };

  var handleClose = () => {
    setShowCreateUser(false);
    setRole_choosen(null);
    setEmail_user("");
    setFirst_name_user("");
    setLast_name_user("");
    setOptions_displayed(options_displayed);
    setApi_request("mobaUserCreateWithEmail");
    setUser_to_update_id(0);
    setTeam_display("none");
    setSelected_sites([]);
    setSelected_teams([]);
  };

  var handleChange = (e) => {
    /*	this.setState({
			[e.target.name]: e.target.value,
		}); */
    //convert to hook
    if (e.target.name === "email_user") setEmail_user(e.target.value);
    if (e.target.name === "first_name_user") setFirst_name_user(e.target.value);
    if (e.target.name === "last_name_user") setLast_name_user(e.target.value);
  };

  const handleUserCreate = () => {
    new Api().fetch(
      api_request,
      {
        id: user_to_update_id,
        email: email_user,
        first_name: first_name_user,
        last_name: last_name_user,
        role: loc(role_choosen, "fr"),
        teams: selector_team.selectedList.map((a) => a.id),
        sites: selector_site.selectedList.map((a) => a.id),
      },
      (result) => {
        if (result.err) alert(result.msg);
        window.location.reload(false);
      }
    );
  };

  const getData = () => {
    var data = props.data;
    data = data.map((data) => {
      if (data.name != undefined) {
        data.brand = data.name.split(" ")[0];
        //data.model is all that is left
        data.model = data.name.split(" ").slice(1).join(" ");
      }
      const unit = props.moba.unit
      if (unit === "miles") {
        data.odometer = convertMiles(data.odometer);
      }
      
      if (!data.soh) {
        data.soh_dressed = "-";
      } else {
        data.soh_dressed = Math.round(data.soh * 100) / 100;
        data.soh_dressed = Math.min(data.soh_dressed, 100) + "%";
      }

      if (!data.odometer) {
        data.odometer_dressed = "-";
      } else {
        data.odometer_dressed = getLocalString(Math.round(data.odometer), props.lang) + (unit === "miles" ? " miles" : " km");
      }

      if (data.degradation && data.degradation !== loc("Indisponible", props.lang)) {
        data.degradation_dressed = data.degradation + " %";
      }
      else {
        data.degradation_dressed = "-";
      }
      return data;
    });
    return [...data];
  };

  const columns = React.useMemo(() => props.columns, [props.columns]);

  const data = getData();

  //didmount
  // useEffect(() => {
  //   props.moba.updateInfos(() => {
  //     setEntity_details(props.moba.entity_details);
  //   });
  // }, [props]);

  return (
    <div className="text-gray-900 h-full flex-1">
      <main className="mx-auto h-full">
        <Table
          columns={columns}
          data={data}
          filterAnalytics={props.filterAnalytics}
          changeFiltersAnalytics={props.changeFiltersAnalytics}
          lang={props.lang}
          type={props.type}
          analyticsStat={props.analyticsStat}
          add={props.add}
          adduser={true}
          show={props.show ? props.show : showCreateUserFunc}
          moba={props.moba}
        />
      </main>
      <SideModal
        show={showCreateUser}
        title={user_title}
        onConfirm={handleUserCreate}
        onCancel={handleClose}
        confirmLabel={user_button_title}
        lang={props.lang}
      >
        <InputForm
          lang={props.lang}
          defaultValue={email_user}
          handleChange={handleChange}
          title={loc("Email", props.lang)}
        />
        <InputForm
          lang={props.lang}
          defaultValue={first_name_user}
          handleChange={handleChange}
          title={loc("Prénom", props.lang)}
        />
        <InputForm
          lang={props.lang}
          defaultValue={last_name_user}
          handleChange={handleChange}
          title={loc("Nom", props.lang)}
        />

        <div>
          <label className="text-sm ml-0 mr-auto mt-4 mb-2 font-aeonik text-gray-600 font-medium">
            {loc("Rôle", props.lang)}
          </label>
          <Dropdown
            options={props.moba.roles_assignable}
            onChange={_onSelectRole}
            value={loc(role_choosen, props.lang)}
            placeholder={loc("Choisissez une option", props.lang)}
          />
        </div>
        <div
          className={
            "flex flex-col " +
            (role_choosen == "Super Admin" ? "invisible" : "")
          }
        >
          <MultipleDropwdown
            title={loc("Sites", props.lang)}
            options={entity_details?.sites.map((site) => {
              return {
                name: site.name,
                id: site.id,
              };
            })}
            selector={selector_site}
            selectedValue={selected_sites}
            onChange={onChangeSite}
          ></MultipleDropwdown>
        </div>
        <div
          className="flex flex-col"
          id="team_selector"
          style={{
            display: team_display,
          }}
        >
          <MultipleDropwdown
            title={loc("Équipe", props.lang)}
            options={options_displayed}
            selector={selector_team}
            selectedValue={selected_teams}
          ></MultipleDropwdown>
        </div>
      </SideModal>
    </div>
  );
}

export default MobaTable;
