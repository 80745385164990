import React, { Component } from "react";
import Header from "./Header";
import MobaTable from "./MobaTable";
import Pannel from "./Pannel";
import {
	DownloadCSV,
	SelectColumnFilterDate,
	NbRows,
} from "./Table"; // new
import { loc, includesMultiType, isNullable } from "../../helpers";
import { FilterMoba } from "./FilterMoba";
import { useToast } from "../../contexts/ToastContext";
import Tooltip from "../components/Tooltip";
import LoadingSpinner from "../components/LoadingSpinner";

import mixpanel from "mixpanel-browser";

import jp from "jsonpath";

const CopyButton = ( { url, lang }) => { // Toast resetting pagination bug fix
	const { showToast } = useToast()
	return (

		<div className="group">
		<button
			onClick={() => {
				navigator.clipboard.writeText(
					url.url
				);

				showToast(
					loc('Url du certificat copiée', lang),
					loc("L'url du certificat a été copiée dans le presse-papier.", lang)
					, 'success');
			}}
		>
      <Tooltip message={loc("Copier l'URL", lang)} position='left'>
        <img
          src="assets/copy-certificate.svg"
          width="20"
          alt="copy"
          className=" filter grayscale hover:grayscale-0"
        />
      </Tooltip>
    </button>
	</div>
	)
}
class CertificatesPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			moba: props.moba,
			certificates: null,
      delegations: [],
      delegators: [],
		};

		this.columns = [
			{
				Header: loc("VIN", this.props.lang),
				accessor: "VIN",
				Cell: row => {
					return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
							  {row.row.original.VIN}
              </div>
            </Tooltip>
					)
				}
			},
			{
				Header: loc("Modèle", this.props.lang),
				accessor: "model",
				Cell: row => {
					return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
							  {row.row.original.model}
              </div>
            </Tooltip>
					);
				}
			},
			{
				Header: loc("SOH", this.props.lang),
				accessor: "soh_dressed",
				Filter: FilterMoba,
				filter: (rows, col_id, filterValue) => {
          return rows.filter((row) => {
            let valid = true;
  
            for (let filterKey in filterValue) {
              const allowEmpty = filterValue[filterKey] === null || includesMultiType(null, filterValue[filterKey], "id");

              if (filterValue[filterKey] !== undefined && filterValue[filterKey] !== null) {
                const rowValue = jp.value(row.original, filterKey);
                if (isNullable(rowValue)) {
                  valid = allowEmpty
                } else {
                  valid = includesMultiType(
                    rowValue,
                    filterValue[filterKey],
                    "id"
                  );
                }
              }
              if (!valid) {
                break;
              }
            }
            return valid;
          });
        },
        filters: ["SITE", "TEAM", "DIAG_COST", "COUNTRY", "DELEGATOR", "DELEGATION"],
				lang: this.props.lang,
				moba: this.props.moba,
			},
      {
          Header: loc("Kilométrage", this.props.lang),
          accessor: "odometer_dressed",
          lang: this.props.lang,
          moba: this.props.moba,
      },
      {
        Header: loc("Pays", this.props.lang),
        accessor: "country",
        sortType: "object",
        type: loc("pays", this.props.lang),
        Cell: (row) => {
          return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
               <span>
                {row.row.original.country ? `${row.row.original.country.emoji} ${row.row.original.country.name}` : '-'}
              </span>
              </div>
            </Tooltip>
          )
        },
      },
			{
				Header: loc("Date", this.props.lang),
				accessor: "date_diag",
				Filter: SelectColumnFilterDate, // new
				filter: (rows, id, filterValue) => {
          if (filterValue === null) {
            return rows;
          }
					return rows.filter(row => {
						const rowValue = row.values[id];
						return filterValue.includes(rowValue);
					});
				},
				sortType: (a, b) => {
					//convert date DD/MM/YYYY HH:MM:SS to YYYYMMDDHHMMSS
					const date_a_converted = a.values.date_diag.replace(
						/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/,
						"$3$2$1$4$5"
					);
					const date_b_converted = b.values.date_diag.replace(
						/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/,
						"$3$2$1$4$5"
					);
					return date_b_converted - date_a_converted;
				},
				lang: this.props.lang,
				Cell: row => {
					return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
							  { row.row.original.date_diag }
              </div>
            </Tooltip>
					)
				}
			},
			{
				Header: loc("Client", this.props.lang),
				accessor: "delegator.entity",
				lang: this.props.lang,
        sortType: "object",
				Cell: row => {
					return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
                { row.row.original.delegator?.entity?.name || '-' }
              </div>
            </Tooltip>
					);
				},
        show: () => {
          return this.state.delegators.length > 0
        }
			},
			{
				Header: loc("Société d'inspection", this.props.lang),
				accessor: "delegation.entity",
				lang: this.props.lang,
        sortType: "object",
				Cell: row => {
					return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
                { row.row.original.delegation?.entity?.name || '-' }
              </div>
            </Tooltip>
					);
				},
        show: () => {
          return this.state.delegations.length > 0
        }
			},
			{
				Header: loc("Site", this.props.lang),
				accessor: "site",
        sortType: "object",
				Cell: row => {
					return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
                { row.row.original.site.name }
              </div>
            </Tooltip>
					);  
				}
			},
			{
				Header: loc("Équipe", this.props.lang),
				accessor: "team",
        sortType: "object",
				Filter: NbRows,
				lang: this.props.lang,
				type: loc("diagnostic", this.props.lang),
        typePlural: loc("diagnostics", this.props.lang),
				Cell: row => {
					return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
                { row.row.original.team.name }
              </div>
            </Tooltip>
					);
				}
			},
			{
				Header: loc("Opérateur", this.props.lang),
				accessor: "user",
				lang: this.props.lang,
				Cell: row => {
					return (
            <Tooltip overflowOnly={ true } className="truncate">
              <div className="truncate">
                { row.row.original?.user?.trim() || '-' }
              </div>
            </Tooltip>
					);
				}
			},
			{
				Header: loc(" ", this.props.lang),
				Filter: DownloadCSV,
        type: loc("diagnostics", this.props.lang),
				id: "download-csv",
				lang: this.props.lang,
        minWidth: 200,
				Cell: row => {
					return (
						<div className="flex flex-1 justify-end gap-4 items-center">
							<div className="group">
								<a
									href={row.row.original.url}
									target="_blank"
									rel="noreferrer"
								>
                <Tooltip message={loc("Ouvrir", this.props.lang)} position='left'>
									<img
										src="assets/print-certificate.svg"
										width="20"
										alt="print"
										className=" filter grayscale hover:grayscale-0"
									/>
                  </Tooltip>
								</a>
							</div>
							{/* <CopyButton url={row.row.original.url} lang={this.props.lang} /> */}
							<div className="group">
								<a href={row.row.original.download_url} download>
                  <Tooltip message={loc("Télécharger", this.props.lang)} position='left'>
                    <img
                      src="assets/download-certificate.svg"
                      width="20"
                      alt="download"
                      className="filter grayscale hover:grayscale-0"
                    />
                  </Tooltip>
								</a>
							</div>
              {row.row.original.technical_report_download_url && <div className="group">
								<a href={row.row.original.technical_report_download_url} download>
                  <Tooltip message={loc("Télécharger le rapport technique", this.props.lang)} position='left'>
                    <img
                      src="assets/wrench-outline.svg"
                      width="20"
                      alt="download"
                      className="filter grayscale hover:grayscale-0"
                    />
                  </Tooltip>
								</a>
							</div>}
						</div>
					);
				},
			},
		];
	}

  async initCertificates() {
    await this.state.moba.fetchCertificates(() => {
      this.state.moba.certificates.map((certificate) => {
        if (typeof certificate.country === 'string') {
          certificate.country = this.state.moba.getCountryFromCode(certificate.country);
        }
      });
      this.setState({ 
        certificates: this.state.moba.certificates,
        delegations: this.state.moba.getAllDelegations(),
        delegators: this.state.moba.getAllDelegators()
      });
    });
  }
	async componentDidMount() {
    mixpanel.track("User Opened Page", { page: "Diagnostics" });
    this.state.moba.updateInfos(async () => {
      await this.initCertificates();
    })
    
	}

	render() {
		if (this.state.moba.isLoading() || !this.state.certificates)
			return (
				<div className="flex flex-row min-h-screen m-0">
					<Pannel type="certificates" lang={this.props.lang} />
					<LoadingSpinner/>
				</div>
			);
		else				
			return (
				<div className="flex h-full min-h-screen overflow-y-hidden">
					<Pannel type="certificates" lang={this.props.lang} />
					<div className="flex flex-col flex-grow mx-8">
						<Header
							connected={true}
							moba={this.props.moba}
							lang={this.props.lang}
						/>
						<div className="my-4 w-full flex flex-1" id="TABLE_CONTAINER">
							<MobaTable
								data={this.state.certificates}
								columns={this.columns}
								lang={this.props.lang}
								type={loc("certificates", this.props.lang)}
								moba={this.state.moba}
							/>
						</div>
					</div>
				</div>
			);
	}
}

export default CertificatesPage;
