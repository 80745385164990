import React, { Component } from "react";
import Header from "./Header";
import Pannel from "./Pannel";
import "react-dropdown/style.css";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { TimeScale } from "chart.js";
import { loc } from "../../helpers";
import Api from "../../Api";
import ManagementBox from "./ManagementBox";
import { DataChartBox } from "./DataChartBox";
import { DatePicker } from "./DatePicker";
import FilteredView from "./FilteredView";
import { FilterMoba } from "./FilterMoba";
import Tooltip from "../components/Tooltip";
import LoadingSpinner from "../components/LoadingSpinner";

import mixpanel from "mixpanel-browser";

const getDefaultDates = () => {
  return [
    Date.UTC(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate()
    ),
    Date.UTC(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 1
    ),
  ];
};

const defaultYearToPrint = (lang) => {
  const dates = getDefaultDates();
  const langFormatted = lang.replace("_", "-");
  const formatter = new Intl.DateTimeFormat(langFormatted, {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
  return formatter.format(dates[0]) + " - " + formatter.format(dates[1]);
};
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function arraysAreEqual(array1, array2) {
  if (array1 === array2) {
    return true;
  }

  if (array1?.length !== array2?.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
}

Chart.register(CategoryScale);

const colors_by_year = ["#00A988", "#00D3AA", "#B3F2E6"];

export const LineChart = ({ chartData, lang }) => {
  if (chartData !== null) {
    return (
      <div className="flex flex-grow h-full">
        <Line
          data={chartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label =
                      " " + (context.parsed.y ? context.parsed.y : "0");
                    label += " " + loc("diagnostic(s)", lang);
                    return label;
                  },
                  title: function (context) {
                    return context[0].raw.xWithOffset;
                  }
                },
              },
            },
            tension: 0.4,
            fill: {
              target: "origin",
              above: "rgba(0, 211, 170, 0.1)",
            },
          }}
        />
      </div>
    );
  } else return <div>Loading...</div>;
};

class Dashboard extends Component {
  constructor(props) {
    super();
    this.hoverSiteTimeoutRef = React.createRef();
    this.hoverCountryTimeoutRef = React.createRef();
    this.state = {
      data_chart: null,
      moba: props.moba,
      entity_details: {},
      opacity: "opacity-50",
      show: false,
      selected: "soh",
      showCreateSite: false,
      showCreateTeam: false,
      showTeam: false,
      showDongle: false,
      team_choosen: null,
      type_choosen: loc("Nom/Prénom", props.lang),
      site_choosen: null,
      dongle_choosen: null,
      site_name: null,
      society_name: null,
      street: null,
      city: null,
      postal_code: null,
      selector_admin: { selectedList: [] },
      selector_managers: { selectedList: [] },
      selector_dongles: { selectedList: [] },
      selected_dongles: [],
      selector_operators: { selectedList: [] },
      selected_operators: [],
      emails_selected: [],
      selector_teams: { selectedList: [] },
      selected_teams: [],
      selector_sites: { selectedList: [] },
      selected_sites: [],
      selector_types: { selectedList: [] },
      selected_types: [],
      selector_dongles: { selectedList: [] },
      selected_dongles: [],
      selector_operators: { selectedList: [] },
      selected_operators: [],
      anonymous_options: "no",
      team_name: null,
      team_title: loc("Créer une équipe", props.lang),
      user_button_team: loc("Créer", props.lang),
      api_request: "mobaTeamCreate",
      team_id: 0,
      siteHover: false,
      countryHover: false,
      sites: props.moba.all_sites,
      current_year: new Date().getFullYear(),
      year_chosen: new Date().getFullYear(),
      open: false,
      siteHover: false,
      siteEnterHover: false,
      diagnosticHover: false,
      diagnosticEnterHover: false,
      sites_checked: null,
      countries_checked: null,
      opacity: "opacity-50",
      days: getDefaultDates(),
      show: false,
      selected: "soh",
      toPrint: defaultYearToPrint(props.lang),
      date_filter: getDefaultDates(),
      loading: true,
    };

    this.getSitesCheckedNames = () => {
      return this.state.sites
        .filter((s) => this.state.sites_checked.includes(s.id))
        .map((site) => site.name);
    };

    this.getFilteredMonths = () => {
      var months = [];
      var startDate = new Date(new Date(this.state.date_filter[0]).setDate(1));
      var endDate = new Date(this.state.date_filter[1]);

      const lang = props.lang.replace("_", "-");

      const formatterMonth = new Intl.DateTimeFormat(lang, {
        month: "long",
      });

      while (startDate <= endDate) {
        var formattedMonth = formatterMonth.format(startDate);
        if (!months.includes(formattedMonth)) {
          months.push(formattedMonth);
        }
        startDate.setMonth(startDate.getMonth() + 1);
      }

      return months;
    };

    this.onChangeDate = (days) => {
      //convert date to timestamp
      if (!days?.length) {
        var date = getDefaultDates();
      } else {
        var date = [new Date(days[0]).getTime(), new Date(days[1]).getTime()];
      }
      this.setState({
        date_filter: date,
      });
    };

    this.setDays = (days) => {
      if (!days.from) {
        this.setState({ days: getDefaultDates() });
      } else {
        this.setState({ days: days });
      }
    };

    this.setToPrint = (toPrint) => {
      if (toPrint === "Date") {
        this.setState({ toPrint: defaultYearToPrint(this.props.lang) });
      } else {
        this.setState({ toPrint: toPrint });
      }
    };

    this.handleClose = () => {
      this.setState({
        showTeam: false,
        showDongle: false,
        team_choosen: null,
        type_choosen: loc("Nom/Prénom", props.lang),
        site_choosen: null,
        dongle_choosen: null,
        showCreateSite: false,
        showCreateTeam: false,
        site_name: null,
        society_name: null,
        street: null,
        city: null,
        postal_code: null,
        selector_admin: { selectedList: [] },
        selector_managers: { selectedList: [] },
        selector_dongles: { selectedList: [] },
        selected_dongles: [],
        selector_operators: { selectedList: [] },
        selected_operators: [],
        emails_selected: [],
        anonymous_options: "no",
        team_name: null,
        team_title: loc("Créer une équipe", props.lang),
        user_button_team: loc("Créer", props.lang),
        api_request: "mobaTeamCreate",
        team_id: 0,
        show: false,
      });
    };
    this.handleShowTeam = () => {
      this.setState({
        showTeam: true,
        site_choosen: null,
      });
    };
    this.handleShowDongle = () => {
      this.setState({
        showDongle: true,
      });
    };
    this.handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };
    this.deleteTeam = (e) => {
      new Api().fetch(
        "mobaTeamDelete",
        { id: this.state.team_id },
        (result) => {
          if (result.err) alert(result.msg);
          this.handleClose();
          this.updateInfos();
          window.location.reload();
        }
      );
    };

    this.updateInfos = () => {
      this.state.moba.updateInfos(() => {
        this.setState({
          entity_details: this.state.moba.entity_details,
        });
      });
    };

    this.showEditTeam = (
      name,
      dongles,
      operators,
      siteId,
      emails,
      id,
      infos
    ) => {
      dongles.forEach((dongle) => {
        this.state.selector_dongles.selectedList.push(dongle);
        this.state.selected_dongles.push(dongle);
      });
      operators.forEach((operator) => {
        this.state.selector_operators.selectedList.push(operator);
        this.state.selected_operators.push(operator);
      });

      var site_choosen = this.state.entity_details.sites.filter((site) => {
        return site.id === siteId;
      })[0];

      this.setState({
        showCreateTeam: true,
        team_title: loc("Modifier une équipe", this.props.lang),
        user_button_team: loc("Modifier", this.props.lang),
        api_request: "mobaTeamEdit",
        team_id: id,
        team_name: name,
        site_choosen: {
          value: site_choosen.id,
          label: site_choosen.name,
        },
        emails_selected: emails.map((email) => {
          return email.email;
        }),
        anonymous_options: "no",
      });
    };

    this._onSelectTeam = (team) => {
      this.setState({
        team_choosen: team.value,
      });
    };
    this._onSelectType = (type) => {
      this.setState({
        type_choosen: type.value,
      });
    };
    this._onSelectDongle = (dongle) => {
      this.setState({
        dongle_choosen: dongle.value,
      });
    };
    this._onSelectSite = (site) => {
      this.setState({
        site_choosen: {
          value: site.value,
          label: site.label,
        },
      });
    };

    this.handleShowCreateSite = () => {
      this.setState({ showCreateSite: true });
    };
    this.showCreateTeam = () => {
      this.setState({ showCreateTeam: true });
    };

    this.selectEmail = (email) => {
      this.setState({ emails_selected: email });
    };
    this.onChange = (e) => {
      this.setState({
        anonymous_options: e,
      });
    };
    this.customStyles = {
      overlay: {
        background: "rgba(0, 0, 0, 0.5)",
      },
      content: {
        height: "200px", //or maxHeight
      },
    };

    this.refreshCertificates = () => {
      this.setState({ loading: true }, () => {
        this.state.moba.fetchCertificatesYearly(
          this.state.sites_checked,
          this.state.countries_checked,
          this.state.date_filter,
          () => {
            this.state.moba.computeYearlyCertificates(this.state.date_filter);
            const datasets = Object.keys(
              this.state.moba.number_certificates_yearly
            )
              .sort()
              .reverse()
              .map((serieLabel, i) => {
                const data =
                  this.state.moba.number_certificates_yearly[serieLabel];
                return {
                  label: serieLabel,
                  data,
                  borderColor: colors_by_year[i],
                  backgroundColor: colors_by_year[i],
                };
              });
            this.setState({
              data_chart: {
                datasets: datasets,
              },
              loading: false,
            });

          }
        );
      });
    };

    this.setShow = (show) => {
      this.setState({
        show: show,
      });
    };
    this.handleClickOutside = (event) => {
      //if class auth-modal has not the class hidden and the user clicks outside the modal, the modal closes
      if (!event.target.closest(".auth-modal")) {
        this.setState({
          show: false,
          open: false,
        });
      }
    };
    document.addEventListener("click", this.handleClickOutside);

    this.handleMouseEnterSite = () => {
      clearTimeout(this.hoverSiteTimeoutRef.current);
      this.setState({ siteHover: true, countryHover: false });
    };

    this.handleMouseLeaveSite = () => {
      this.hoverSiteTimeoutRef.current = setTimeout(() => {
        this.setState({ siteHover: false });
      }, 500);
    };
    this.handleMouseEnterCountry = () => {
      clearTimeout(this.hoverCountryTimeoutRef.current);
      this.setState({ countryHover: true, siteHover: false });
    };

    this.handleMouseLeaveCountry = () => {
      this.hoverCountryTimeoutRef.current = setTimeout(() => {
        this.setState({ countryHover: false });
      }, 500);
    };

    this.setSitesFiltered = (sites_checked) => {
      if (sites_checked) {
        this.setState({
          sites_checked: sites_checked,
        });
      } else {
        this.setState({
          sites_checked: null,
        });
      }
    };

    this.setCountriesFiltered = (countries_checked) => {
      if (countries_checked) {
        this.setState({
          countries_checked: countries_checked,
        });
      } else {
        this.setState({
          countries_checked: null,
        });
      }
    };
  }

  componentDidMount() {
    mixpanel.track("User Opened Page", { page: "Dashboard" });
    this.state.moba.updateInfos(() => {
      this.setState({
        entity_details: this.state.moba.entity_details,
      });
      this.refreshCertificates();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const shouldRefreshCertifs =
      prevState.date_filter !== this.state.date_filter ||
      !arraysAreEqual(prevState.sites_checked, this.state.sites_checked) ||
      !arraysAreEqual(
        prevState.countries_checked,
        this.state.countries_checked
      );

    if (shouldRefreshCertifs) {
      this.refreshCertificates();
    }
    if (prevState.show !== this.state.show) {
      if (this.state.show === true && this.state.open === true) {
        this.setState({ open: false });
      }
    }
    if (prevState.open !== this.state.open) {
      if (this.state.open === true && this.state.show === true) {
        this.setState({ show: false });
      }
    }

    var ev_cars = document.getElementsByClassName("ev-cars");
    var width = this.state.moba.percent_electric + "%";
    if (ev_cars.length > 0) {
      ev_cars[0].style.width = width;
    }
    var phev_cars = document.getElementsByClassName("phev-cars");
    var width = this.state.moba.percent_phev + "%";
    if (phev_cars.length > 0) {
      phev_cars[0].style.width = width;
    }

    var soh_under = document.getElementsByClassName("soh-under");
    var width = this.state.moba.percent_soh_under_85 + "%";
    if (soh_under.length > 0) soh_under[0].style.width = width;
    var soh_over = document.getElementsByClassName("soh-over");
    var width = this.state.moba.percent_soh_over_85 + "%";
    if (soh_over.length > 0) soh_over[0].style.width = width;
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  shouldDisplayChart = () => {
    return (
      this.state.moba.certificates_yearly?.n?.diagnostics?.length ||
      this.state.moba.certificates_yearly["n-1"]?.diagnostics?.length
    );
  };

  chartSection = () => {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }
    if (!this.shouldDisplayChart()) {
      return (
        <div className="flex">
          <p className="text-grey-500 text-center">
            {loc("Aucune donnée disponible", this.props.lang)}
          </p>
        </div>
      );
    }

    return (
      <>
        <LineChart
          chartData={this.state.data_chart}
          lang={this.props.lang}
        ></LineChart>
        <div className="m-auto px-2 flex flex-none h-full flex-column justify-center">
          <div className="">
            {Object.keys(
              this.state.data_chart != null
                ? this.state.data_chart.datasets
                : []
            ).map((key, index) => {
              const value = this.state.data_chart.datasets[key].label;
              const color = colors_by_year[index];
              return (
                <div className="flex items-center" key={key}>
                  <div
                    className={"w-2 h-2 rounded-full"}
                    style={{
                      backgroundColor: color,
                    }}
                  ></div>
                  <p className="ml-2 font-inter text-grey-600 text-sm">
                    {value}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  render() {
    if (this.state.data_chart === null)
      return (
        <div className="flex flex-row min-h-screen m-0">
          <Pannel type="dashboard" lang={this.props.lang} />
          <LoadingSpinner />
        </div>
      );
    return (
      <div className="flex h-full min-h-screen overflow-y-hidden">
        <Pannel type="dashboard" lang={this.props.lang} />
        <div className="flex flex-col flex-grow pb-8">
          <Header
            connected={true}
            moba={this.props.moba}
            lang={this.props.lang}
          />
          <div className="w-full h-fit flex flex-row mt-12">
            <div className="flex flex-col gap-9 py-0 mx-3">
              {this.props.moba.isSuperAdmin() && (
                <ManagementBox
                  length={this.state.entity_details.sites?.length}
                  lang={this.props.lang}
                  create={this.handleShowCreateSite}
                  type="SITE"
                  img="assets/sites.svg"
                  title={loc("Sites", this.props.lang)}
                />
              )}
              <ManagementBox
                length={
                  this.state.entity_details.teams?.filter((team) => {
                    return team.name !== "Site Admin" && team.name !== "Admin";
                  }).length
                }
                lang={this.props.lang}
                create={this.showCreateTeam}
                type="TEAM"
                img="assets/teams.svg"
                title={loc("Équipes", this.props.lang)}
              />
              <ManagementBox
                length={this.state.entity_details.dongles?.length}
                lang={this.props.lang}
                create={this.handleShowDongle}
                buttonLabel={loc("Associer", this.props.lang)}
                type="DEVICE"
                img="assets/dongles.svg"
                title={loc("Boîtiers", this.props.lang)}
                noCta={!this.props.moba.isSuperAdmin()}
              />
            </div>
            <div className="flex flex-col mx-3 height-total flex-1">
              <div className="flex auth-modal">
                <FilterMoba
                  filterOpened={false}
                  setFilterOpened={() => null}
                  column={{
                    moba: this.props.moba,
                    filters: ["SITE", "COUNTRY"],
                    lang: this.props.lang,
                  }}
                  setType={() => null}
                  sitesFiltered={this.state.sites_checked}
                  setSitesFiltered={this.setSitesFiltered}
                  countriesFiltered={this.state.countries_checked}
                  setCountriesFiltered={this.setCountriesFiltered}
                ></FilterMoba>
                <DatePicker
                  opacity={this.state.opacity}
                  days={this.state.days}
                  setDays={this.setDays}
                  show={this.state.show}
                  setShow={this.setShow}
                  toPrint={this.state.toPrint}
                  setToPrint={this.setToPrint}
                  onChange={this.onChangeDate}
                  lang={this.props.lang}
                />
              </div>
              <div className="w-full mx-auto border-0.5 border-grey-200 mt-6"></div>

              <div className="flex mb-6">
                <FilteredView
                  lang={this.props.lang}
                  itemsFiltered={this.state.sites_checked}
                  setItemsFiltered={this.setSitesFiltered}
                  filterType="SITES"
                />
                <FilteredView
                  lang={this.props.lang}
                  itemsFiltered={this.state.countries_checked}
                  setItemsFiltered={this.setCountriesFiltered}
                  filterType="COUNTRIES"
                />
              </div>

              <div className="flex flex-row mx-auto w-full justify-between">
                <DataChartBox
                  value={
                    this.state.moba.certificates_yearly.n.metrics.count
                  }
                  value_diff={
                    this.state.moba.certificates_yearly.evolution.count
                  }
                  lang={this.props.lang}
                  label={loc("diagnostics", this.props.lang)}
                  type="DIAGNOSTIC"
                  comp_type="yoy"
                />
                <DataChartBox
                  value={
                    this.state.moba.certificates_yearly.n.metrics.avg_soh
                  }
                  value_diff={
                    this.state.moba.certificates_yearly.evolution.avg_soh
                  }
                  lang={this.props.lang}
                  label={loc("SOH moyen", this.props.lang)}
                  type="SOH"
                  comp_type="yoy"
                />
                <DataChartBox
                  value={
                    this.state.moba.certificates_yearly.n.metrics.avg_age
                  }
                  value_diff={
                    this.state.moba.certificates_yearly.evolution.avg_age
                  }
                  lang={this.props.lang}
                  label={loc("Moyenne en mois", this.props.lang)}
                  type="AGE"
                  reverse
                  comp_type="yoy"
                />
                <DataChartBox
                  value={
                    this.state.moba.certificates_yearly.n.metrics.avg_odometer
                  }
                  value_diff={
                    this.state.moba.certificates_yearly.evolution.avg_odometer
                  }
                  lang={this.props.lang}
                  label={capitalizeFirstLetter(
                    loc("Moyenne en ", this.props.lang) +
                      (this.state.moba.unit === "km"
                        ? loc("kilomètres", this.props.lang)
                        : loc("miles", this.props.lang))
                  )}
                  type="ODOMETER"
                  reverse
                  comp_type="yoy"
                />
              </div>

              <div className="flex flex-col bg-white rounded-xl border border-grey-200 shadow-sm mx-auto w-full my-6">
                <p className="text-aeonik text-grey-800 text-xl my-3 ml-3">
                  {loc("Diagnostics", this.props.lang)}
                </p>{" "}
                <div className="flex w-full justify-center items-center h-72 p-3 pt-0">
                  {this.chartSection()}
                </div>
              </div>
              <div className="mx-auto w-full mt-6 rounded-md border border-grey-200 shadow-sm">
                <div className="text-aeonik text-grey-800 text-xl my-3 ml-3">
                  <Tooltip>{loc("Diagnostics", this.props.lang)}</Tooltip>
                </div>{" "}
                <div className="my-3 w-full px-4 mb-4">
                  {
                    (
                      this.state.moba.certificates_yearly?.n?.diagnostics?.length &&
                      (
                        this.state.moba.percent_electric !== null || this.state.moba.percent_phev !== null ||
                        this.state.moba.percent_soh_under_85 !== null || this.state.moba.percent_soh_over_85 !== null
                      )
                    ) ? (
                    <>
                      {(this.state.moba.percent_electric !== null || this.state.moba.percent_phev !== null) && (
                        <div className="mb-4 text-sm">
                          <div className="flex relative">
                            <p className="font-aeonik text-branding-600 bg-white z-50">
                              Electric véhicles (EV)
                            </p>
                            <p className="font-aeonik text-branding-200 ml-auto bg-white z-50">
                              PHEV
                            </p>
                          </div>
                          <div className="flex relative">
                            <div className="h-6 ev-cars bg-branding-600 rounded-l "></div>
                            <div className="h-6 phev-cars bg-branding-200 rounded-r relative">
                              <p
                                className="absolute value-ev font-aeonik text-branding-600 top-6"
                                style={{ left: "-10px" }}
                              >
                                {(this.state.moba.percent_electric || 0)+ "%"}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {(this.state.moba.percent_soh_under_85 !== null || this.state.moba.percent_soh_over_85 !== null) && (
                        <div className="mb-5 text-sm">
                          <div className="flex relative">
                            <p className="font-aeonik text-error bg-white z-50">
                              {"SOH < 85%"}
                            </p>
                            <p className="font-aeonik text-branding-600 ml-auto bg-white z-50">
                              {"SOH > 85%"}
                            </p>
                          </div>
                          <div className="flex relative">
                            <div className="h-6 soh-under bg-error rounded-l"></div>
                            <div className="h-6 soh-over bg-branding-600 rounded-r relative">
                              <p
                                className="absolute font-aeonik text-error value-soh-under top-6"
                                style={{ left: "-10px" }}
                              >
                                {(this.state.moba.percent_soh_under_85 || 0) + "%"}
                              </p>
                            </div>
                          </div>
                        </div>
                    )}
                    </>
                  ) : (
                    <div className="flex justify-center items-center h-28">
                      <p className="text-grey-500 text-center">
                        {loc("Aucune donnée disponible", this.props.lang)}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
