import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { loc } from "../../helpers";
import { Disconnect, Divider } from "./Icons";
import Api from "../../Api";

import mixpanel from "mixpanel-browser";

export default function NavBar(props) {
  var history = useHistory();
  //import removeCookie
  const removeCookie = useCookies()[2];

  const disconnect = () => {
    new Api()
      .fetch("logout", {}, (result) => {
        console.debug(
          `Successfully logged out from server.\nResponse: ${result}`
        );

        mixpanel.track("User Logged Out");
        mixpanel.reset();

        props.moba.logout();

        window.location.reload(false);
      })
      .catch((err) => {
        removeCookie("miner", { path: "/" });
        console.error(
          `Could not logout from server. Login out from frontend. Original error: \n ${err}`
        );
        window.location.reload(false);
      });
  };
  const goToUsers = () => {
    history.push("/users");
  };

  const goToSites = () => {
    history.push("/sites");
  };

  const goToProfile = () => {
    history.push("/personal-info");
  };

  // close menu when clicked outside
  window.onclick = function (event) {
    //if click outside the div with class dropbtn
    if (!event.target.closest(".dropbtn")) {
      var x = document.getElementById("navbar");
      if (typeof x !== "undefined" && x !== null) x.style.display = "none";
    }
  };

  return (
    <div
      className="flex flex-col whitespace-nowrap absolute right-10 top-20 bg-white shadow-lg rounded-lg p-3 m-auto rounded-lg z-50"
      id="navbar"
      style={{ display: "none" }}
    >
      <div className="profile flex flex-col">
        <h4 className="font-aeonik text-lg mb-3">
          {loc("Profil", props.lang)}
        </h4>
        <div className="menu-items flex flex-col gap-5">
          <button className="flex" onClick={goToProfile}>
            <img src="assets/user.svg" width="20" alt="user" />
            <p className="ml-4 hover:text-grey-500">
              {loc("Profil & préférences", props.lang)}
            </p>
          </button>
        </div>
      </div>
      <div className="mt-3 mb-3">
        <Divider />
      </div>
      <div className="my-company">
        <h4 className="font-aeonik text-lg mb-2">
          {loc("Gérer mon entreprise", props.lang)}
        </h4>

        <div className="menu-items flex flex-col gap-2">
          <button className="flex mt-2" onClick={goToSites}>
            <img src="assets/credit-card.svg" width="20" alt="credit-card" />
            <p className="ml-3 hover:text-grey-500">
              {loc("Sites", props.lang)}
            </p>
          </button>
          <button className="flex mt-2" onClick={goToUsers}>
            <img src="assets/check.svg" width="20" alt="check" />
            <p className="ml-3 hover:text-grey-500">
              {loc("Utilisateurs & autorisations", props.lang)}
            </p>
          </button>
        </div>
      </div>
      <div className="mt-3 mb-3">
        <Divider />
      </div>
      <div className="logout">
        <button className="flex items-center" onClick={disconnect}>
          <div className="ml-0">
            <Disconnect />
          </div>
          <p className="ml-3 hover:text-grey-500">
            {loc("Se déconnecter", props.lang)}
          </p>
        </button>
      </div>
    </div>
  );
}
