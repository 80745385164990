import React, { useEffect, useState } from "react";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { loc } from "../../helpers";
import {
  CrossIcon
} from "./Icons";

import { fr, enGB, enUS } from 'date-fns/locale';



export function DatePicker(props) {
  const getDateFnsLocaleFromLang = (lang) => {
    switch (lang) {
      case "fr_FR":
        return fr;
      case "en_WW":
        return enUS;
      case "en_UK":
        return enGB;
      default:
        return enUS;
    }
  
  }
	const handleDateSelection = dates => {
    if (!dates) {
      props.setDays(null);
      return;
    }

    const datesInUTC = {};

    if (dates.from) {
      datesInUTC.from = new Date(Date.UTC(dates.from.getFullYear(), dates.from.getMonth(), dates.from.getDate()));
    }

    if (dates.to) {
      datesInUTC.to = new Date(Date.UTC(dates.to.getFullYear(), dates.to.getMonth(), dates.to.getDate()));
    }
    
		props.setDays(datesInUTC);
	};

	const submitDates = () => {
		props.setShow(false);
    const lang = props.lang.replace('_', '-');
    const formatter = new Intl.DateTimeFormat(lang, {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });

		if(props.days?.from) {
			//print day in full format
			props.setToPrint(
				formatter.format(props.days.from) +
					(props.days.to ? " - " + formatter.format(props.days.to) : "")
			);
			props.onChange([props.days.from, props.days.to ? props.days.to : props.days.from]);
		}
	}
	const footer = (
		<div>
			<div className="w-full h-0.5 bg-grey-200 mb-3"></div>
			<div className="flex">
				<div className="flex m-auto h-12">
					<button
						className="flex border rounded-md px-4 mr-4 h-full"
						onClick={() => {
							props.setShow(false);
							props.setDays([]);
							props.setToPrint("Date");
							props.onChange([]);
						}}
					>
						<p className="m-auto">{loc('Annuler', props.lang)}</p>
					</button>
					<button
						disabled={!props.days?.from}
						className={`flex border rounded-md px-4 ml-4 bg-moba-green h-full ${!props.days?.from ? "opacity-50 cursor-not-allowed" : null}`}
						onClick={submitDates}
					>
						<p className="text-white m-auto">{loc('Appliquer', props.lang)}</p>
					</button>
				</div>
			</div>
		</div>
	);

	useEffect(() => {
		const handleClickOutside = event => {
			//if class auth-modal has not the class hidden and the user clicks outside the modal, the modal closes
			if (!event.target.closest(".date-modal") && props.show) {
					submitDates();
				}
		};

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [props]);

	return (
		<div className="ml-3 relative date-modal">
			<div
				className="p-2 border-1 border-grey-300 text-grey-500 hover:border-branding-500 hover:text-branding-500 rounded-md h-11 justify-center whitespace-nowrap flex items-center cursor-pointer" style={{minWidth: 75}}
				onClick={() => {
					props.setShow(!props.show);
					if (props.setFilterOpened) props.setFilterOpened("date");
				}}
			>
				{props.toPrint}
				{props.days?.from && (
					<button onClick={() => {
            props.setDays([]);
            props.setToPrint("Date");
            props.onChange(null);
          }} className="ml-3">
            <CrossIcon className="hover:text-branding-200"/>
					</button>	
				)
				}
			</div>
			{props.show && (
				<div className="absolute shadow z-50 opacity-100 bg-white">
					<style>{`.custom-head { color: red }`}</style>
					<DayPicker
						mode="range"
						selected={props.days}
						disabled={{after: new Date()}}
						modifiersClassNames={{
							selected: "bg-moba-green text-white",
							
						}
						}
						onSelect={handleDateSelection}
						onRemove={props.setDays}
						footer={footer}
            locale={getDateFnsLocaleFromLang(props.lang)}
					/>
				</div>
			)}
		</div>
	);
}
