function Toast({ title, message, type, onClose, hideClass }) {
    return (
      <div
      className={`${type} ${hideClass} animate-toast-enter ${type === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'} transform fixed top-5 right-5 bg-white rounded-lg shadow-lg flex p-4 max-w-lg`} style={{zIndex: 9999}}>
        <div>
            <img src={type === 'success' ? '/assets/success.svg' : '/assets/error.svg'} className="w-12 h-12" />
        </div>
        <div className="mx-4">
            <h3 className="font-aeonik text-base text-grey-600 mb-2">{title}</h3>
            <p className="font-medium text-grey-600">{message}</p>
        </div>
        <div className="cursor-pointer" onClick={onClose}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23 13L13 23M13 13L23 23" stroke="#667085" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
      </div>
    );
  }

export default Toast;