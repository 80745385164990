import React, { useState, useEffect, useMemo } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import LoginPage from "./components/pages/LoginPage";
import ProfilePage from "./components/pages/ProfilePage";
import Management from "./components/pages/Management";
import Moba from "./Moba";

import "./App.css";

import CertificatesPage from "./components/pages/CertificatesPage";
import Code from "./components/pages/Code.js";
import Infos from "./components/pages/Infos.js";

import Cookies from "js-cookie";
import Api from "./Api.js";
import AnalyticsBoxes from "./components/pages/AnalyticsBoxes";
import Analytics from "./components/pages/Analytics";
import AnalyticsUser from "./components/pages/AnalyticsUser";
import Dashboard from "./components/pages/Dashboard";
import Users from "./components/pages/Users";
import Sites from "./components/pages/Sites";
import Welcome from "./components/pages/Welcome";
import Thanks from "./components/pages/Thanks";
import { ToastProvider } from "./contexts/ToastContext";

import mixpanel from 'mixpanel-browser';
import { getUnitFromLocale, getLangFromLocale, localeFromLang } from "./helpers.js";

const getBrowserLocale = () => {
  const browserLocale = navigator.language || navigator.userLanguage;
  if (browserLocale === "en-US") {
    return "en_WW";
  }
  if (browserLocale === "fr-FR") {
    return "fr_FR";
  }
  if (browserLocale === "en-UK") {
    return "en_UK";
  }
  return 'en_WW';
}

const App = () => {
  const [moba, setMoba] = useState(new Moba());
  const [loggedIn, setLoggedIn] = useState(Cookies.get("miner") != null);
  const [onboarded, setOnboarded] = useState(null);
  const [locale, setLocale] = useState(getBrowserLocale());

  const lang = useMemo(() => {
    return getLangFromLocale(locale);
  }, [locale]);

  const unit = useMemo(() => {
    return getUnitFromLocale(locale);
  }, [locale]);

  useEffect(() => {
    mixpanel.init(
      process.env.REACT_APP_MIXPANEL_TOKEN,
      { 
        debug: process.env.NODE_ENV === 'development' || process.env.REACT_APP_MIXPANEL_DEBUG === 'true',
        track_links_timeout: 3000,
        ignore_dnt: true
      }
    );
  }, []);

  const mixpanelRegister = (user) => {
    const registerProperties = {
      "User ID": user.id,
      "Email": user.email,
      "Role" : user.role,
      "Entity ID": user.entity.id,
      "Entity Name": user.entity.name,
    };

    if (process.env.NODE_ENV === 'development') {
      registerProperties["Dev"] = true;
    }
    mixpanel.register(registerProperties);
  }

  const handleLocaleChange = (newLocale) => {
    setLocale(newLocale);
    moba.handleLocaleChange(newLocale);
  };

  useEffect(() => {
    new Api().fetch("mobaMe", {}, (result) => {
      if (result.err === -1 || !result.user?.id) {
        setLoggedIn(false);
        return;
      }
      setLoggedIn(true);
      moba.setDetails(result);
      const locale = result.user.locale;

      handleLocaleChange(locale);

      mixpanel.identify(result.user.id);
      mixpanelRegister(result.user);


      let url = window.location.href.split("?")[1];

      if (url && url.includes("login=success")) {
        window.history.pushState({}, document.title, window.location.pathname);
        mixpanel.track("User Logged In", { "User ID": result.user.id });
      }

      if (result.user.email === result.user.entity.name) {
        setOnboarded(false);
      } else {
        setOnboarded(true);
      }
    });
  }, []);

  if (!loggedIn)
    return (
      <div className="flex flex-col self-center m-auto">
        <ToastProvider>
          <BrowserRouter>
            <div className="flex flex-col">
              <Switch>
                <Route path="/login">
                  <LoginPage moba={moba} lang={lang} />
                </Route>
                <Route path="/code">
                  <Code moba={moba} lang={lang} />
                </Route>
                <Route path="/">
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </div>
          </BrowserRouter>
        </ToastProvider>
      </div>
    );
  else if (onboarded === null) return null;
  else if (!onboarded) {
    return (
      <div className="flex flex-col self-center m-auto">
        <ToastProvider>
          <BrowserRouter>
            <div className="flex flex-col">
              <Switch>
                <Route path="/welcome">
                  <Welcome moba={moba} lang={lang} unit={unit} />
                </Route>
                <Route path="/thanks">
                  <Thanks moba={moba} lang={lang} unit={unit} />
                </Route>
                <Route path="/">
                  <Redirect to="/welcome" lang={lang} unit={unit} />
                </Route>
              </Switch>
            </div>
          </BrowserRouter>
        </ToastProvider>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col self-center m-auto">
        <ToastProvider>
          <BrowserRouter>
            <div className="flex flex-col">
              <Switch>
                <Route path="/profile">
                  <ProfilePage moba={moba} lang={lang} unit={unit} />
                </Route>
                <Route path="/management">
                  <Management moba={moba} lang={lang} unit={unit} locale={locale} />
                </Route>
                <Route path="/car">
                  <Analytics moba={moba} lang={lang} unit={unit} page="car" />
                </Route>
                <Route path="/teams">
                  <Analytics moba={moba} lang={lang} unit={unit} page="teams" />
                </Route>
                <Route path="/graphs">
                  <Analytics
                    moba={moba}
                    lang={lang}
                    unit={unit}
                    page="graphs"
                  />
                </Route>
                <Route path="/dashboard">
                  <Dashboard moba={moba} lang={lang} unit={unit} />
                </Route>
                <Route path="/users">
                  <Users moba={moba} lang={lang} unit={unit} />
                </Route>
                <Route path="/sites">
                  <Sites moba={moba} lang={lang} unit={unit} page="sites" />
                </Route>
                <Route path="/billing">
                  <Sites moba={moba} lang={lang} unit={unit} page="billing" />
                </Route>
                <Route path="/certificates">
                  <CertificatesPage moba={moba} lang={lang} unit={unit} />
                </Route>
                <Route path="/personal-info">
                  <Infos
                    moba={moba}
                    setLocale={handleLocaleChange}
                    locale={locale}
                    lang={lang}
                  />
                </Route>
                <Route path="/">
                  <Redirect to="/dashboard" lang={lang} unit={unit} />
                </Route>
              </Switch>
            </div>
          </BrowserRouter>
        </ToastProvider>
      </div>
    );
  }
};

export default App;
